import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuthUser } from 'react-auth-kit'
import AELogoIcon from '../../../assets/Icons/AELogoIcon';
import { SearchNavHamburgerStyles, SearchNavContainerStyles, SearchNavLabelStyles, SearchNavUserStyles } from './StyleOverrides';
import LeagueSelect from './LeagueSelect';
import Search from './Search';
import LogOut from '../../atoms/Auth/LogOut';

interface IProps {
  toggle: () => void;
}

function SearchNavBar({ toggle }: IProps) {
  const auth = useAuthUser();
  const user = auth();

  return (
    <Box flexGrow={1}>
      <AppBar
        position="fixed"
        sx={SearchNavContainerStyles}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggle}
            edge="start"
            sx={SearchNavHamburgerStyles}
          >
            <MenuIcon />
          </IconButton>
          <AELogoIcon />
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={SearchNavLabelStyles}
          >
            AccessElias
          </Typography>
          <LeagueSelect />
          <Search />
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            variant="body1"
            noWrap
            component="div"
            sx={SearchNavUserStyles}
          >
            Welcome { user?.first_name }
          </Typography>
          <LogOut />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default SearchNavBar;
