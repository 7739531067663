import React from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { TournamentSummaryDataType } from '../../types/franchise';

interface IProps {
  label: string;
  data: TournamentSummaryDataType;
}

function WinLossChip({ chipValue }: { chipValue: string}) {
  const chipColor = chipValue.match(/w/i) ? '#ECFDF3' : '#FEF3F2' ;
  const fontColor = chipValue.match(/w/i) ? '#027A48' : '#B42318';
  return (
    <Chip label={chipValue} sx={{ backgroundColor: `${chipColor}`, color: `${fontColor}` }} />
  );
};

function TournamentFactCard({ label, data }: IProps) {
  return (
    <>
      <Typography component="div" variant='body1' fontWeight="600" mt={2}>{label}</Typography>
      
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1} alignItems='center'>
          { data.seed && data.region && <Typography component="div" variant='body1' mt={1}>{data.seed} in {data.region}</Typography> }
        </Stack>
        {
          data.games.map((game) => (
            <Stack key={game.id} direction="row" spacing={1}  alignItems='center'>
              <WinLossChip chipValue={`${game.result.toUpperCase()}-${game.teamScore}-${game.opponentScore}`} />
              <Typography component="div" variant='body1' mt={1}>{game.round} versus {game.opponentName}</Typography>
            </Stack>
          ))
        }
      </Stack>
    </>
  );
}

export default TournamentFactCard;
