import React from 'react';
import { GridCellParams, GridColumnHeaderParams } from '@mui/x-data-grid';
import EXTERNAL_URLS from '../../utils/router/external_urls';
import { AppLeagueRoutesType } from '../../utils/router/urls';
import { FEATURE_TOGGLES } from '../../utils/common/constants';

// TODO: punting on selectively linking game IDs/dates for the time being
// const FIRST_AE2_GAME = 1992110600;

const generateGolfHoleClass = (column: string, params: GridCellParams<string>) => {
  const scoreToParKey = `${column}_to_par`;
  switch (params.row && params.row[scoreToParKey]) {
    case 'birdie':
      return 'hole-cell-birdie';
    case 'double_bogey':
      return 'hole-cell-double-or-worse';
    case 'triple_bogey':
      return 'hole-cell-double-or-worse';
    case 'quad_or_worse':
      return 'hole-cell-double-or-worse';
    case 'bogey':
      return 'hole-cell-bogey';
    case 'eagle':
      return 'hole-cell-eagle';
    default:
      return '';
  }
};

const generateExtraClass = (column: string, params: GridCellParams<string>) => {
  const pattern = /^hole_([1-9]|1[0-8])_score$/;
  return pattern.test(column) ? generateGolfHoleClass(column, params) : '';
};

export const generateCellClass = (sortColumn: string) =>
  function generateClass(
    params: GridCellParams<string> | GridColumnHeaderParams<string>
  ) {
    const cellClass = ''
    const extraClass = generateExtraClass(params.field, params);

    console.log(extraClass);
    const sortClass = sortColumn === params.field ? 'data-grid-active-cell' : 'data-grid-inactive-cell';
    return `${cellClass} ${extraClass} ${sortClass}`;
  };

const generatePlayerProfileRoute = (
  params: GridCellParams<string>,
  valueFieldName: string,
  internalUrls?: AppLeagueRoutesType
) => {
  const playerId = params.row[valueFieldName];
  if (FEATURE_TOGGLES.playerPages && internalUrls) {
    return internalUrls.player.replace(':playerId', playerId);
  }
  return EXTERNAL_URLS.NBA.playerProfile.replace(':playerId:', playerId);
};

const generateFranchiseProfileRoute = (
  params: GridCellParams<string>,
  column: string,
  internalUrls?: AppLeagueRoutesType
) => {
  const { row } = params;
  const franchiseId = column === 'franchise_season__abbr_3' ? row.franchise_id : row.opponent_id;
  if (FEATURE_TOGGLES.teamPages && internalUrls) {
   return internalUrls.team.replace(':teamId', franchiseId); 
  }
  return EXTERNAL_URLS.NBA.franchiseProfile.replace(
    ':franchiseId:',
    franchiseId
  );
};

  // @TODO: punting on selectively linking game IDs/dates for the time being
  // if (gameId < FIRST_AE2_GAME) {
  //   return '';
  // }
const generateBoxscoreRoute = (
  params: GridCellParams<string>,
  valueFieldName: string,
  internalUrls?: AppLeagueRoutesType
) => {
  const gameId = params.row[valueFieldName];
  if (FEATURE_TOGGLES.gamePages && internalUrls) {
    return internalUrls.game.replace(':gameId', gameId);
  }
  return EXTERNAL_URLS.NBA.boxscore.replace(':gameId:', gameId);
};

const generateGolferRoute = (
  params: GridCellParams<string>,
  valueFieldName: string,
  internalUrls?: AppLeagueRoutesType
) => {
  const golferId = params.row[valueFieldName];
  return internalUrls.golfer.replace(':golferId', golferId);
};

const generateTournamentHistoryRoute = (
  params: GridCellParams<string>,
  valueFieldName: string,
  internalUrls?: AppLeagueRoutesType
) => {
  const tournamentId = params.row[valueFieldName];
  return internalUrls.golfTournamentHistory.replace(':tournamentId', tournamentId);
};

const generateTournamentRoute = (
  params: GridCellParams<string>,
  valueFieldName: string,
  internalUrls?: AppLeagueRoutesType
) => {
  const tournamentId = params.row[valueFieldName];
  return internalUrls.golfTournament.replace(':tournamentId', tournamentId);
};

const generateCourseRoute = (
  params: GridCellParams<string>,
  valueFieldName: string,
  internalUrls?: AppLeagueRoutesType
) => {
  const courseId = params.row[valueFieldName];
  return internalUrls.golfCourse.replace(':courseId', courseId);
};

const generateLinkRoute = (column: string, params: GridCellParams<string>, internalUrls?: AppLeagueRoutesType) => {
  switch (column) {
    case 'player__display_name':
      return generatePlayerProfileRoute(params, 'player__player_id', internalUrls);
    case 'display_name':
      return generatePlayerProfileRoute(params, 'player_id', internalUrls);
    case 'franchise_season__abbr_3':
      return generateFranchiseProfileRoute(params, column, internalUrls);
    case 'opponent_season__abbr_3':
      return generateFranchiseProfileRoute(params, column, internalUrls);
    case 'game__date':
      return generateBoxscoreRoute(params, 'game_id', internalUrls);
    case 'first':
      return generateBoxscoreRoute(params, 'first_id', internalUrls);
    case 'last':
      return generateBoxscoreRoute(params, 'last_id', internalUrls);
    case 'golfer__display_name':
      return generateGolferRoute(params, 'golfer__id', internalUrls);
    case 'tournament__name':
      return generateTournamentHistoryRoute(params, 'tournament__id', internalUrls);
    case 'tournament__year':
      return generateTournamentRoute(params, 'tournament__id', internalUrls);
    case 'tournament__golf_course':
      return generateCourseRoute(params, 'tournament__golf_course_id', internalUrls);
    default:
      return '';
  }
};

export const renderCell = (column: string, internalUrls?: AppLeagueRoutesType) =>
  function generate(params: GridCellParams<string>) {
    const value = params.row[column];
    const route = generateLinkRoute(column, params, internalUrls);
    return route !== '' ? <a href={route} target="_blank" rel="noreferrer">{value}</a> : value;
  };
