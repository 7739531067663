import {
  DEFAULT_ACTIVE_STATUS,
  DEFAULT_HOME_AWAY,
  DEFAULT_OVERTIME_STATUS,
  DEFAULT_ROOKIE_STATUS,
  DEFAULT_WIN_LOSS_STATUS,
  activeStatusOptions,
  homeAwayOptions,
  overtimeStatusOptions,
  rookieStatusOptions,
  winLossStatusOptions
} from "../../compounds/FastFilters/constants";
import { GameClass, GameClassType } from '../../../api/types';
import { DEFAULT_STREAK_RANGE_OPTION } from "../../pages/Nba/PlayerStreakFinder/constants";
import { DEFAULT_SPAN_RANGE_OPTION } from "../../pages/Nba/PlayerSpanFinder/constants";
import { StatFilter } from "../../compounds/EliasStatsFilter/filter-stats-utils";
import { ContextAdvancedFiltersType, ContextFilterType, ContextGameTypeFiltersType } from "../../types/context";
import { SelectedStatFilterType } from "../../types/statsFilter";

const CURRENT_SEASON_YEAR = '2022';

const mapEqualitySymbolToSearchParam = (symbol?: string): string => {
  switch (symbol) {
    case '<=':
      return '__lte=';
    case '>=':
      return '__gte=';
    default:
      return '=';
  }
};

const mapValueToSearchValue = (
  id: string,
  value?: string,
  searchModifierFunction?: (
    value: string,
    modifierValue: number,
  ) => string,
  searchModifierValue?: string,
): string | number => {
  if (!value) return '';
  if (searchModifierFunction) {
    const modValue = Number(searchModifierValue);
    return searchModifierFunction(value, modValue);
  }
  if (id.includes('percentage')) {
    return (Number(value) / 100).toFixed(3);
  }
  if (id === 'seconds') {
    return Number(value) * 60;
  }
  return value;
};

const mapIdToSearchId = (id: string) => {
  if (id.includes('_per_game')) {
    return id.replace('_per_game', '');
  }
  return id;
};

export const formatStatsFilterForSearch = (
  filters: SelectedStatFilterType[],
  statsFilterOptions: StatFilter[],
  searchModifierValue?: string,
): string => {
  if (filters.length === 0) return '';

  let years: number[] = [];
  let searchFilters = filters.map((filter) => {
    const statFilterOption = statsFilterOptions.find(
      (statFilter) => statFilter.id === filter.id
    );
    if (statFilterOption) {
      const symbol = mapEqualitySymbolToSearchParam(filter.equalitySymbol);
      const formattedValue = mapValueToSearchValue(filter.id, filter.value, statFilterOption.searchModifier, searchModifierValue);
      const formattedId = mapIdToSearchId(filter.id);
      if (statFilterOption.year) {
        years = years.concat(statFilterOption.year);
      }
      return `${formattedId}${symbol}${formattedValue}`;
    }

    return '';
  });
  const filterYear = Math.max(...years);
  if (filterYear > 0) {
    searchFilters = searchFilters.concat(`season__gte=${filterYear}`);
  }
  return searchFilters.join('&');
};

const formatGameTypeFilters = (gameFilterContext: ContextGameTypeFiltersType): string => {
  const filters: string[] = [];

  // handle radio buttons and their inputs
  if (gameFilterContext.holiday_group.length > 0) {
    switch (gameFilterContext.holiday_group) {
      case 'game__date': {
        const id = gameFilterContext.holiday_group;
        const start = gameFilterContext.holiday_group_value;
        const end = gameFilterContext.holiday_group_end_value;
        const formattedStart = start.replace(/[/|-]/g, '%2F');
        const formattedEnd = end.replace(/[/|-]/g, '%2F');
        filters.push(`${id}__gte=${formattedStart}&${id}__lte=${formattedEnd}`);
        break;
      }
      default:
        filters.push(`${gameFilterContext.holiday_group}=1`);
        break;
    }
  }

  if (gameFilterContext.postseason_group.length > 0) {
    switch (gameFilterContext.postseason_group) {
      case 'game_franchise__game_number':
        filters.push(`game_franchise__game_number=${gameFilterContext.postseason_group_value}&game__game_class_type=PS`);
        break;
      case 'opening_game':
        filters.push(`game_franchise__game_number=1&game__game_class_type=PS`);
        break;
      default:
        filters.push(`${gameFilterContext.postseason_group}=1&game__game_class_type=PS`);
        break;
    }
  }

  if (gameFilterContext.season_group.length > 0) {
    switch (gameFilterContext.season_group) {
      case 'season_range': {
        const formattedStart = gameFilterContext.season_group_value.split('-')[0];
        const formattedEnd = gameFilterContext.season_group_end_value.split('-')[0];
        filters.push(`season__gte=${formattedStart}&season__lte=${formattedEnd}`);
        break;
      }
      case '3_point_era':
        filters.push(`season__gte=1979&season__lte=${CURRENT_SEASON_YEAR}`);
        break;
      case 'since_merger':
        filters.push(`season__gte=1976&season__lte=${CURRENT_SEASON_YEAR}`);
        break;
      case 'shot_clock_era':
        filters.push(`season__gte=1954&season__lte=${CURRENT_SEASON_YEAR}`);
        break;
      default:
        filters.push(`season=${CURRENT_SEASON_YEAR}`);
        break;
    }
  }

  // handle checkboxes
  const validCheckboxFilters = Object.entries(gameFilterContext).filter((pair) => pair[1] === true);
  validCheckboxFilters.forEach((checkboxFilter: [string, any]) => {
    const key = checkboxFilter[0].toUpperCase();
    if (GameClassType.includes(key)) {
      filters.push(`game__game_class_type=${key}`);
    }
    if (GameClass.includes(key.toUpperCase())) {
      filters.push(`game__game_class=${key}`);
    }
  });

  return filters.join('&');
};

const formatAdvancedFilters = (advancedFilterContext: ContextAdvancedFiltersType): string => {
  const filters: string[] = [];
  if (advancedFilterContext.player_age.selected) {
    const searchEquality = mapEqualitySymbolToSearchParam(advancedFilterContext.player_age.equalityOption);
    filters.push(`age${searchEquality}${advancedFilterContext.player_age.value}`);
  }
  if (advancedFilterContext.seasons.selected) {
    const searchEquality = mapEqualitySymbolToSearchParam(advancedFilterContext.seasons.equalityOption);
    filters.push(`seasons${searchEquality}${advancedFilterContext.seasons.value}`);
  }

  const validChecboxFilters = Object.entries(advancedFilterContext).filter((pair) => pair[1] === true);
  validChecboxFilters.forEach((checkboxFilter: [string, any]) => {
    filters.push(`${checkboxFilter[0]}=1`);
  });
  return filters.join('&');
};

export const appendContextFilters = (
  localFilters: string[],
  context: ContextFilterType,
  statsFilterOptions: StatFilter[],
  searchModifierValue?: string,
) => {
  const filters = localFilters;

  if (context.selectedStatsFilters && context.selectedStatsFilters.length > 0) {
    filters.push(formatStatsFilterForSearch(context.selectedStatsFilters, statsFilterOptions, searchModifierValue));
  }

  filters.push(formatGameTypeFilters(context.gameTypeFilters));

  filters.push(formatAdvancedFilters(context.advancedFilters));

  // Boolean filters
  if (activeStatusOptions.map(option => option.id).includes(context.activeStatus) &&
      context.activeStatus !== DEFAULT_ACTIVE_STATUS) {
    filters.push(`player__active=${context.activeStatus === 'active'}`);
  }
  if (homeAwayOptions.map(option => option.id).includes(context.homeAway) &&
      context.homeAway !== DEFAULT_HOME_AWAY) {
    filters.push(`home_game=${context.homeAway === 'home'}`);
  }
  if (rookieStatusOptions.map(option => option.id).includes(context.rookieStatus) &&
      context.rookieStatus !== DEFAULT_ROOKIE_STATUS) {
    filters.push(`rookie=${context.rookieStatus === 'rookie'}`);
  }
  if (winLossStatusOptions.map(option => option.id).includes(context.winLossStatus) &&
      context.winLossStatus !== DEFAULT_WIN_LOSS_STATUS) {
    filters.push(`win=${context.winLossStatus === 'win'}`);
  }
  if (overtimeStatusOptions.map(option => option.id).includes(context.overtimeStatus) &&
      context.overtimeStatus !== DEFAULT_OVERTIME_STATUS) {
    filters.push(`game__overtime_played=${context.overtimeStatus === 'overtime'}`);
  }

  // Enumerated filters
  if (context.playerObject) {
    filters.push(`player__id=${context.playerObject.id}`);
  }
  if (context.teamObject) {
    filters.push(`franchise_season__franchise_id=${context.teamObject.franchise_id}`);
  }
  if (context.venueObject) {
    filters.push(`game__venue=${context.venueObject.venue}`);
  }
  if (context.opponentObject) {
    filters.push(`opponent_season__franchise_id=${context.opponentObject.franchise_id}`);
  }
  if (context.streakGameNumber) {
    filters.push(`streak_length=${context.streakGameNumber}`);
  }
  if (context.streakRangeOption && context.streakRangeOption !== DEFAULT_STREAK_RANGE_OPTION) {
    filters.push(`streak_range=${context.streakRangeOption}`);
  }
  if (context.spanLength) {
    filters.push(`span_length=${context.spanLength}`);
  }
  if (context.spanRangeOption && context.spanRangeOption !== DEFAULT_SPAN_RANGE_OPTION) {
    filters.push(`span_range=${context.spanRangeOption}`);
  }
  if (context.groupBy && context.groupBy !== 'none') {
    filters.push(`group_by=${context.groupBy}`);
  };

  // remove any empty strings or null values so they dont get sent to api  
  const apiFilters = filters.filter(Boolean);
  return apiFilters;
};

export const searchEnabled = (
  context: ContextFilterType,
  pageSpecificSearchEnabled: (context: ContextFilterType) => boolean,
  // eslint-disable-next-line arrow-body-style
): boolean => {
  return context.searchEnabled && pageSpecificSearchEnabled(context) && (
    // stats filters selected
    context.selectedStatsFilters.length > 0 ||

    // fast filters selected
    (context.activeStatus !== DEFAULT_ACTIVE_STATUS && context.activeStatus.length > 0) ||
    (context.rookieStatus !== DEFAULT_ROOKIE_STATUS && context.rookieStatus.length > 0) ||
    (context.homeAway !== DEFAULT_HOME_AWAY && context.homeAway.length > 0) ||
    (context.winLossStatus !== DEFAULT_WIN_LOSS_STATUS && context.winLossStatus.length > 0) ||
    (context.overtimeStatus !== DEFAULT_OVERTIME_STATUS && context.overtimeStatus.length > 0) ||

    // autocomplete filter selected
    Boolean(context.playerObject && context.playerObject.id) ||
    Boolean(context.venueObject && context.venueObject.venue) ||
    Boolean(context.teamObject && context.teamObject.id) ||
    Boolean(context.opponentObject && context.opponentObject.id) ||

    // modal filters
    context.gameTypeFilters.holiday_group.length > 0 ||
    context.gameTypeFilters.postseason_group.length > 0 ||
    context.gameTypeFilters.season_group.length > 0 ||
    context.gameTypeFilters.pin || context.gameTypeFilters.sem || context.gameTypeFilters.rd1 ||
    context.gameTypeFilters.as || context.gameTypeFilters.fin || context.gameTypeFilters.con
  );
};
