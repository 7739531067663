import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputAdornment, styled } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CancelRounded from '@mui/icons-material/CancelRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/system/Box';
import { PlayerInfo } from '../../../api/types';
import './EliasNameFilter.scss';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { useFetchPlayers } from '../../hooks/useFetch';

const StyledAutocomplete = styled(Autocomplete)((props: { textcolor: string, weight: number  }) => ({
  '.MuiInputBase-root': {
    paddingLeft: '35px !important',
    '.MuiAutocomplete-input': {
      color: props.textcolor,
      fontWeight: props.weight,
    },
    '.MuiAutocomplete-endAdornment': {    
      '.MuiAutocomplete-clearIndicator': {
        '.cancel-icon': {
          color: props.textcolor,
          visibility: 'visible',
        }
      }
    },
  },
}));

interface IProps {
  apiUrl: string;
}

function EliasNameFilter({ apiUrl}: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlayerInfo[]>([]);

  const { data: playerData, refetch: refetchPlayers } = useFetchPlayers(inputValue, apiUrl);

  const handleChange = (_event: any, newValue: PlayerInfo | null) => {
    setOptions(newValue ? [newValue, ...options] : options);

    filterDispatchContext({
      type: 'changePlayerObject',
      playerObject: newValue,
      searchEnabled: true,
    });
  };

  const handleInputChange = (_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue !== inputValue) refetchPlayers();
  };
  
  const textColor = filterContext.playerObject && 
    filterContext.playerObject.id ? '#175CD3' : '#101828';

  const fontweight = filterContext.playerObject && 
    filterContext.playerObject.id ? 500 : 400;

  return (
    <Box>
      <StyledAutocomplete
        id="elias-name-filter"
        data-testid="elias-name-filter"
        options={playerData?.results ?? []}
        value={filterContext.playerObject}
        inputValue={inputValue}
        includeInputInList
        filterOptions={(x) => x}
        isOptionEqualToValue={(option: any) =>
          option.id === filterContext.playerObject?.id
        }
        noOptionsText="No matches found."
        getOptionLabel={(option: any) => option.display_name}
        onChange={(event: any, value: any) => handleChange(event, value)}
        onInputChange={handleInputChange}
        renderInput={(params: any) => (
          <TextField {...params} placeholder="Search by Player" InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon className="person-icon" />
              </InputAdornment>
            ),
          }} />
        )}
        renderOption={(props, option: any) => (
          <li {...props} key={option.id}>
            {option.display_name}
          </li>
        )}
        popupIcon={
          <ExpandMoreIcon />
        }
        forcePopupIcon={!filterContext.playerObject?.id}
        clearIcon={
          <CancelRounded className='cancel-icon' />
        }
        textcolor={textColor}
        weight={fontweight}
      />
    </Box>
  );
}

export default EliasNameFilter;
