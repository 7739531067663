import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { TableCell, TableRow } from '@mui/material';
import FactCard from '../../atoms/FactCard/FactCard';
import StyledTable from '../../atoms/StyledTable/StyledTable';
import { PlayerProfileStatsType, PlayerProfileType } from '../../types/player';

interface IProps {
  tournamentHistoryDetails: PlayerProfileType;
  tournamentHistoryResultsData: PlayerProfileStatsType;
};

const yearTournamentHeaders = [
  'Year',
  'Dates Held',
  'Course',
  'Winner',
  'Score to Par',
  'Total Score',
  'Prize Money',
  'Total Purse',
];

const yearTournamentMap = {
  'Year': 'year',
  'Dates Held': 'datesHeld',
  'Course': 'golfCourse',
  'Winner': 'winner',
  'Score to Par': 'scoreToPar',
  'Total Score': 'totalScore',
  'Prize Money': 'prizeMoney',
  'Total Purse': 'totalPurse',
};

const mapStatsData = (data: PlayerProfileStatsType) => {
  const yearData = data.years;

  return Object.keys(yearData).map((key: string) => {
    const yearRow = yearData[key];
    const perTournamentRow = yearRow.per_tournament;

    return {
      id: key,  
      year: key,
      datesHeld: perTournamentRow.datesHeld,
      golfCourse: perTournamentRow.golfCourse,
      winner: perTournamentRow.winner,
      scoreToPar: perTournamentRow.scoreToPar,
      totalScore: perTournamentRow.totalScore,
      prizeMoney: perTournamentRow.prizeMoney,
      totalPurse: perTournamentRow.totalPurse,
    };
  });
};

function GolfTournamentHistoryLayout({ tournamentHistoryDetails, tournamentHistoryResultsData }: IProps) {
  const tableData = mapStatsData(tournamentHistoryResultsData);

  return (
    <Grid container direction="column" sx={{ margin: 0 }}>
      <Grid container direction="row" marginTop={5}>
        <Grid item>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} justifyContent='center'>
            <Typography component="h1" variant="h3" fontWeight="600">{tournamentHistoryDetails.name}</Typography>
            <Typography component="div" variant='body1'>PGA Tour - Major Tournament</Typography>
          </Box>
        </Grid>
      </Grid>  
      <Grid container direction="row" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 3 }}>
        <Grid item xs={3}>
          <FactCard label='First Held' values={[tournamentHistoryDetails.first_year_played]} />
          <FactCard label='Last Held' values={[tournamentHistoryDetails.last_year_played]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Next Held' values={[tournamentHistoryDetails.next_played]} />
          <FactCard label='Next Venue' values={[tournamentHistoryDetails.next_course]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Reigning Champion' values={[tournamentHistoryDetails.reigning_champion]} />
          <FactCard label='Most Wins' values={[tournamentHistoryDetails.most_wins]} />
        </Grid>
      </Grid>

      <Grid container mt={5} marginRight={5}>
        <Grid item xs={12}>
          <StyledTable
            uniqueRowKey='id'
            headers={yearTournamentHeaders}
            data={tableData.reverse()}
            dataMap={yearTournamentMap}
            linkableItems={['year', 'golfCourse', 'winner', 'golfer', 'tournamentName']}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={14}>
                  <h5 style={{ display: 'inline-block', fontSize: '1.4em', marginRight: 3 }}>Tournament History</h5>
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GolfTournamentHistoryLayout;