import { ColumnMappingType } from "../../../types/results";
import { SelectedStatFilterType } from "../../../types/statsFilter";
import { showHideColumns } from "../../common/results";

const DEFAULT_COL_WIDTH = 80;
const TINY_COL_WIDTH = 10;
const SMALL_COL_WIDTH = 80;
const MEDIUM_COL_WIDTH = 145;
const WIDE_COL_WIDTH = 185; // fits longest player display name at 24 characters

export const ungroupedColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: 40,
    sortable: false,
    display: 'alwaysFirst',
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'Golfer',
    field: 'golfer__display_name',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Year',
    field: 'tournament__year',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Name',
    field: 'tournament__name',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'RD #',
    field: 'round_number',
    width: TINY_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'RD Score',
    field: 'round_score',
    width: SMALL_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'RD To Par',
    field: 'round_score_to_par',
    width: SMALL_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Course',
    field: 'tournament__golf_course',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Type',
    field: 'tournament__tournament_class',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: ' ',
    field: 'loc',
    width: TINY_COL_WIDTH,
    sortable: false,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Finish',
    field: 'finish',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Score',
    field: 'tournament_score',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Score To Par',
    field: 'tournament_score_to_par',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'F9 To Par',
    field: 'front_9_score_to_par',
    width: SMALL_COL_WIDTH,
    display: 'alwaysFirst',
  },
    {
    headerName: 'B9 To Par',
    field: 'back_9_score_to_par',
    width: SMALL_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: '1',
    field: 'hole_1_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '2',
    field: 'hole_2_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '3',
    field: 'hole_3_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '4',
    field: 'hole_4_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '5',
    field: 'hole_5_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '6',
    field: 'hole_6_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '7',
    field: 'hole_7_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '8',
    field: 'hole_8_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '9',
    field: 'hole_9_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'OUT',
    field: 'front_9_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '10',
    field: 'hole_10_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '11',
    field: 'hole_11_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '12',
    field: 'hole_12_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '13',
    field: 'hole_13_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '14',
    field: 'hole_14_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '15',
    field: 'hole_15_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '16',
    field: 'hole_16_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '17',
    field: 'hole_17_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
    {
    headerName: '18',
    field: 'hole_18_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'IN',
    field: 'back_9_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysThird',
  },
];

export const playerColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerFranchiseColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Franchise',
    field: 'franchise_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerOpponentColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Opponent',
    field: 'opponent_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerFranchiseSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Franchise',
    field: 'franchise_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerOpponentSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Opponent',
    field: 'opponent_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const mapColumns = (groupByColumn: string, localFilters: SelectedStatFilterType[]) => {
  switch (groupByColumn) {
    case 'player':
      return playerColumnMapping;
    case 'player-season':
      return playerSeasonColumnMapping;
    case 'player-franchise':
      return playerFranchiseColumnMapping;
    case 'player-opponent':
      return playerOpponentColumnMapping;
    case 'player-franchise-season':
      return playerFranchiseSeasonColumnMapping;
    case 'player-opponent-season':
      return playerOpponentSeasonColumnMapping;
    default:  
      return showHideColumns(ungroupedColumnMapping, localFilters);
  };
};