import React from 'react';
import { Grid } from '@mui/material';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import FinderSelect from '../../atoms/FinderSelect/FinderSelect';
import {
  majorChampionStatusOptions,
  defendingChampionStatusOptions, 
  firstAppearanceStatusOptions,
  professionalStatusOptions,
  handednessOptions,
  nationalityOptions,
  golfRookieOptions,
  cutOptions,
  playoffOptions,
  finishOptions,
  fairwayGrassOptions,
  greensGrassOptions,
} from './constants';

interface IProps {
  showMajorChampion: boolean,
  showDefendingChampion: boolean,
  showFirstAppearance: boolean,
  showProfessionalStatus: boolean,
  showHandedness: boolean,
  showNationality: boolean,
  showRookie: boolean,
  showCut: boolean,
  showPlayoff: boolean,
  showFinish: boolean,
  showFairwayGrass: boolean,
  showGreensGrass: boolean,
}

function GolfFastFilters({
  showMajorChampion,
  showDefendingChampion,
  showFirstAppearance,
  showProfessionalStatus,
  showHandedness,
  showNationality,
  showRookie,
  showCut,
  showPlayoff,
  showFinish,
  showFairwayGrass,
  showGreensGrass,
}: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const handleMajorChampionStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'majorChampionStatusChange',
      majorChampionStatus: selection,
      searchEnabled: true,
    });
  };

  const handleDefendingChampionStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'defendingChampionStatusChange',
      defendingChampionStatus: selection,
      searchEnabled: true,
    });
  };

  const handleFirstAppearanceStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'firstAppearanceStatusChange',
      firstAppearanceStatus: selection,
      searchEnabled: true,
    });
  };

  const handleProfessionalStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'professionalStatusChange',
      professionalStatus: selection,
      searchEnabled: true,
    });
  };

  const handleHandednessChange = (selection: string) => {
    filterDispatchContext({
      type: 'handednessChange',
      handedness: selection,
      searchEnabled: true,
    });
  };

  const handleNationalityChange = (selection: string) => {
    filterDispatchContext({
      type: 'nationalityChange',
      nationality: selection,
      searchEnabled: true,
    });
  };

  const handleGolfRookieStatusChange = (selection: string) => {
    console.log(selection);
    filterDispatchContext({
      type: 'golfRookieStatusChange',
      golfRookieStatus: selection,
      searchEnabled: true,
    });
  };

  const handleCutChange = (selection: string) => {
    filterDispatchContext({
      type: 'cutChange',
      cut: selection,
      searchEnabled: true,
    });
  };

  const handlePlayoffChange = (selection: string) => {
    filterDispatchContext({
      type: 'playoffChange',
      playoff: selection,
      searchEnabled: true,
    });
  };

  const handleFinishChange = (selection: string) => {
    filterDispatchContext({
      type: 'finishChange',
      finish: selection,
      searchEnabled: true,
    });
  };

  const handleFairwayGrassChange = (selection: string) => {
    filterDispatchContext({
      type: 'fairwayGrassChange',
      fairwayGrass: selection,
      searchEnabled: true,
    });
  };

  const handleGreensGrassChange = (selection: string) => {
    filterDispatchContext({
      type: 'greensGrassChange',
      greensGrass: selection,
      searchEnabled: true,
    });
  };


  return (
    <Grid
      container
      spacing={1}
      alignItems="flex-start"
      paddingX={2}
      paddingY={2}
    >
      <Grid item xs={12} paddingY={2}>
        Filters:
      </Grid>
      {showMajorChampion && <Grid item xs={4}>
        <FinderSelect
          label='Major Champion'
          value={filterContext.majorChampionStatus || 'any'}
          options={majorChampionStatusOptions}
          handleSelection={handleMajorChampionStatusChange}
        />
      </Grid>}
      {showDefendingChampion && <Grid item xs={4}>
        <FinderSelect
          label='Defending Champion'
          value={filterContext.defendingChampionStatus || 'any'}
          options={defendingChampionStatusOptions}
          handleSelection={handleDefendingChampionStatusChange}
        />
      </Grid>}
      {showFirstAppearance && <Grid item xs={4}>
        <FinderSelect
          label='First Appearance'
          value={filterContext.firstAppearanceStatus || 'any'}
          options={firstAppearanceStatusOptions}
          handleSelection={handleFirstAppearanceStatusChange}
        />
      </Grid>}
      {showProfessionalStatus && <Grid item xs={4}>
        <FinderSelect
          label='Professional Status'
          value={filterContext.professionalStatus || 'any'}
          options={professionalStatusOptions}
          handleSelection={handleProfessionalStatusChange}
        />
      </Grid>}
      {showRookie && <Grid item xs={4}>
        <FinderSelect
          label='Rookie Status'
          value={filterContext.golfRookieStatus || 'any'}
          options={golfRookieOptions}
          handleSelection={handleGolfRookieStatusChange}
        />
      </Grid>}
      {showNationality && <Grid item xs={4}>
        <FinderSelect
          label='Nationality'
          value={filterContext.nationality || 'any'}
          options={nationalityOptions}
          handleSelection={handleNationalityChange}
        />
      </Grid>}
      {showHandedness && <Grid item xs={4}>
        <FinderSelect
          label='Handedness'
          value={filterContext.handedness || 'any'}
          options={handednessOptions}
          handleSelection={handleHandednessChange}
        />
      </Grid>}
      {showCut && <Grid item xs={4}>
        <FinderSelect
          label='Made/Missed Cut?'
          value={filterContext.cut || 'any'}
          options={cutOptions}
          handleSelection={handleCutChange}
        />
      </Grid>}
      {showFinish && <Grid item xs={4}>
        <FinderSelect
          label='Finish Position'
          value={filterContext.finish || 'any'}
          options={finishOptions}
          handleSelection={handleFinishChange}
        />
      </Grid>}
      {showPlayoff && <Grid item xs={4}>
        <FinderSelect
          label='Playoff?'
          value={filterContext.playoff || 'any'}
          options={playoffOptions}
          handleSelection={handlePlayoffChange}
        />
      </Grid>}
      {showFairwayGrass && <Grid item xs={4}>
        <FinderSelect
          label='Fairway Grass'
          value={filterContext.fairwayGrass || 'any'}
          options={fairwayGrassOptions}
          handleSelection={handleFairwayGrassChange}
        />
      </Grid>}

      {showGreensGrass && <Grid item xs={4}>
        <FinderSelect
          label='Greens Grass'
          value={filterContext.greensGrass || 'any'}
          options={greensGrassOptions}
          handleSelection={handleGreensGrassChange}
        />
      </Grid>}
    </Grid>
  );
}

export default GolfFastFilters;