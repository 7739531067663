const API_BASE = '/api/v1';
const AUTH_BASE = `${API_BASE}/auth`;

export interface IApiUrlFinders {
  franchise_history: string;
  franchise_profile: string;
  franchise_season_manifest: string;
  franchise_season_profile: string;
  player_game_finder: string;
  player_span_finder: string;
  player_streak_finder: string;
  players: string;
  players_manifest: string;
  player_detail: string;
  player_stats: string;
  tournament_history_detail: string;
  tournament_history_results: string;
  tournaments: string;
  courses: string;
  venue_manifest: string;
}

interface IApiUrls {
  auth: {
    login: string;
    logout: string;
    refreshToken: string;
    user: string;
  }
  nba: IApiUrlFinders,
  ncaambb: IApiUrlFinders,
  golf: IApiUrlFinders,
  search: string,
}

const API_NCAAMBB_BASE = `${API_BASE}/basketball/ncaam`;
const API_GOLF_BASE = `${API_BASE}/golf`;

const API_URLS: IApiUrls = {
  auth: {
    login: `${AUTH_BASE}/login/`,
    logout: `${AUTH_BASE}/logout/`,
    refreshToken: `${AUTH_BASE}/token/refresh/`,
    user: `${AUTH_BASE}/user/`,
  },
  nba: {
    franchise_history: '/api/v1/nba/franchise_history/',
    franchise_profile: '/api/v1/nba/franchise_profile/',
    franchise_season_manifest: '/api/v1/nba/franchise_season/manifest/',
    franchise_season_profile: '/api/v1/nba/franchise_season_profile',
    player_game_finder: '/api/v1/nba/player_game_finder/',
    player_span_finder: '/api/v1/nba/player_span_finder/',
    player_streak_finder: '/api/v1/nba/player_streak_finder/',
    players: '/api/v1/nba/person/',
    player_detail: '/api/v1/nba/player_profile',
    player_stats: '/api/v1/nba/player_profile_statistics',
    players_manifest: '/api/v1/nba/person/manifest/',
    venue_manifest: '/api/v1/nba/game/venue_manifest/',
  },
  ncaambb: {
    franchise_history: `${API_NCAAMBB_BASE}/franchise_history/`,
    franchise_profile: `${API_NCAAMBB_BASE}/franchise_profile/`,
    franchise_season_manifest: `${API_NCAAMBB_BASE}/franchise_season/manifest/`,
    franchise_season_profile: `${API_NCAAMBB_BASE}/franchise_season_profile/`,
    player_game_finder: `${API_NCAAMBB_BASE}/player_game_finder/`,
    player_span_finder: `${API_NCAAMBB_BASE}/player_span_finder/`,
    player_streak_finder: `${API_NCAAMBB_BASE}/player_streak_finder/`,
    players: `${API_NCAAMBB_BASE}/person/`,
    player_detail: `${API_NCAAMBB_BASE}/player_profile`,
    player_stats: `${API_NCAAMBB_BASE}/player_profile_statistics`,
    players_manifest: `${API_NCAAMBB_BASE}/person/manifest/`,
    venue_manifest: `${API_NCAAMBB_BASE}/game/venue_manifest/`,
  },
  golf: {
    player_detail: `${API_GOLF_BASE}/player_profile`,
    player_stats: `${API_GOLF_BASE}/player_profile_statistics`,
    tournament_detail: `${API_GOLF_BASE}/tournament_detail`,
    tournament_results: `${API_GOLF_BASE}/tournament_results`,
    tournament_history_detail: `${API_GOLF_BASE}/tournament_history_detail`,
    tournament_history_results: `${API_GOLF_BASE}/tournament_history_results`,
    course_detail: `${API_GOLF_BASE}/course_detail`,
    course_stats: `${API_GOLF_BASE}/course_stats`,
    players: `${API_GOLF_BASE}/person/`,
    players_manifest: `${API_GOLF_BASE}/person/manifest`,
    tournaments: `${API_GOLF_BASE}/tournament/`,
    courses: `${API_GOLF_BASE}/course/`,
    golfer_tournament_finder: `${API_GOLF_BASE}/golfer_tournament_finder/`,
    golfer_round_finder: `${API_GOLF_BASE}/golfer_round_finder/`,
  },
  search: '/api/v1/nba/search/',
};

export default API_URLS;
