export type AppLeagueRoutesType = {
  playerGameFinderSearch: string;
  playerGameFinderResults: string;
  playerStreakFinderSearch: string;
  playerStreakFinderResults: string;
  playerSpanFinderSearch: string;
  playerSpanFinderResults: string;
  team: string;
  player: string;
  game: string;
  dataInfo: string;
  golfer: string;
  golfTournamentHistory: string;
  golferTournamentFinderSearch: string;
  golferTournamentFinderResults: string;
  golferRoundFinderSearch: string;
  golferRoundFinderResults: string;
};

const ROUTER_URLS = {
  home: '/',
  login: '/login',
  nba: {
    playerGameFinderSearch: '/tools/player-game-finder',
    playerGameFinderResults: '/tools/player-game-finder/results',
    playerStreakFinderSearch: '/tools/player-streak-finder',
    playerStreakFinderResults: '/tools/player-streak-finder/results',
    playerSpanFinderSearch: '/tools/player-span-finder',
    playerSpanFinderResults: '/tools/player-span-finder/results',
  },
  ncaambb: {
    playerGameFinderSearch: '/ncaambb/player-game-finder',
    playerGameFinderResults: '/ncaambb/player-game-finder/results',
    playerStreakFinderSearch: '/ncaambb/player-streak-finder',
    playerStreakFinderResults: '/ncaambb/player-streak-finder/results',
    playerSpanFinderSearch: '/ncaambb/player-span-finder',
    playerSpanFinderResults: '/ncaambb/player-span-finder/results',
    team: '/ncaambb/team/:teamId',
    player: '/ncaambb/player/:playerId',
    game: '/ncaambb/game/:gameId',
    dataInfo: '/ncaambb/data-info',
  },
  golf: {
    golfer: '/golf/golfer/:playerId',
    golfTournamentHistory: '/golf/tournament-history/:tournamentId',
    golfTournament: '/golf/tournament/:tournamentId',
    golfCourse: '/golf/course/:courseId',
    golferTournamentFinderSearch: '/golf/golfer-tournament-finder',
    golferTournamentFinderResults: '/golf/golfer-tournament-finder/results',
    golferRoundFinderSearch: '/golf/golfer-round-finder',
    golferRoundFinderResults: '/golf/golfer-round-finder/results',
  },
};

export default ROUTER_URLS;
