import {
  DEFAULT_GOLFER_TOURNAMENT_ORDERING,
  DEFAULT_GOLFER_ROUND_ORDERING,
  baseFetchGolferTournamentStats,
  baseFetchGolferRoundStats
} from './api';
import API_URLS from './api_urls';

export const fetchGolferTournamentStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_GOLFER_TOURNAMENT_ORDERING,
  uuid?: string,
) => baseFetchGolferTournamentStats(
  API_URLS.golf.golfer_tournament_finder, filters, signOut, page, sortBy, uuid
);

export const fetchGolferRoundStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_GOLFER_ROUND_ORDERING,
) => baseFetchGolferRoundStats(
  API_URLS.golf.golfer_round_finder, filters, signOut, page, sortBy
);
