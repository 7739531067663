import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { TableCell, TableRow } from '@mui/material';
import FactCard from '../../atoms/FactCard/FactCard';
import StyledTable from '../../atoms/StyledTable/StyledTable';
import { PlayerProfileStatsType, PlayerProfileType } from '../../types/player';

interface IProps {
  playerDetails: PlayerProfileType;
  statsData: PlayerProfileStatsType;
};

const yearPerTournamentHeaders = [
  'Year',
  'Date',
  'Tournament',
  'Course',
  'Finish',
  'Score to Par',
  'RD1',
  'RD2',
  'RD3',
  'RD4',
  'Total Score',
  'Prize Money',
];

const yearPerTournamentMap = {
  'Year': 'year',
  'Date': 'date',
  'Tournament': 'tournamentName',
  'Course': 'golfCourse',
  'Finish': 'finish',
  'Score to Par': 'scoreToPar',
  'RD1': 'roundOneScore',
  'RD2': 'roundTwoScore',
  'RD3': 'roundThreeScore',
  'RD4': 'roundFourScore',
  'Total Score': 'totalScore',
  'Prize Money': 'prizeMoney',
};

const mapStatsData = (statsData: PlayerProfileStatsType) => {
  const tournamentData = statsData.tournaments;

  return Object.keys(tournamentData).reverse().map((key: string) => {
    
    const perTournamentRow = tournamentData[key];

    return {
      id: key,  
      year: perTournamentRow.year,
      date: perTournamentRow.date,
      tournamentName: perTournamentRow.tournamentName,
      golfCourse: perTournamentRow.golfCourse,
      finish: perTournamentRow.finish,
      prizeMoney: perTournamentRow.prizeMoney,
      scoreToPar: perTournamentRow.scoreToPar,
      roundOneScore: perTournamentRow.roundOneScore,
      roundTwoScore: perTournamentRow.roundTwoScore,
      roundThreeScore: perTournamentRow.roundThreeScore,
      roundFourScore: perTournamentRow.roundFourScore,
      totalScore: perTournamentRow.totalScore,
    };
  });
};

function GolferLayout({ playerDetails, statsData }: IProps) {

  const tableData = mapStatsData(statsData);

  return (
    <Grid container direction="column" sx={{ margin: 0 }}>
      <Grid container direction="row" marginTop={5}>
        <Grid item>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} justifyContent='center'>
            <Typography component="h1" variant="h3" fontWeight="600">{playerDetails.name}</Typography>
            <Typography component="div" variant='body1'>PGA Tour Professional</Typography>
          </Box>
        </Grid>
      </Grid>  
      <Grid container direction="row" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 3 }}>
        <Grid item xs={3}>
          <FactCard label='Height' values={[playerDetails.height]} />
          <FactCard label='Weight' values={[playerDetails.weight]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Birth Date' values={[playerDetails.birthdate]} />
          <FactCard label='Birthplace' values={[playerDetails.birthplace]} />
          <FactCard label='College' values={[playerDetails.college]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Swing' values={[playerDetails.swing]} />
          <FactCard label='Current OWGR' values={[playerDetails.owgr]} />
        </Grid>
      </Grid>

      <Grid container mt={5} marginRight={5}>
        <Grid item xs={12}>
          <StyledTable
            uniqueRowKey='id'
            headers={yearPerTournamentHeaders}
            data={tableData}
            dataMap={yearPerTournamentMap}
            linkableItems={['year', 'golfCourse', 'winner', 'golfer', 'tournamentName']}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={14}>
                  <h5 style={{ display: 'inline-block', fontSize: '1.4em', marginRight: 3 }}>PGA Championship: Year-By-Year Results</h5>
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GolferLayout;