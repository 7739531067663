import React from 'react';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import TeamHistoryTab from './TeamHistoryTab';
import TeamSeasonsTab from './TeamSeasonsTab';
import { FranchiseDetailType, FranchiseHistoryType } from '../../types/franchise';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

interface IProps {
  teamId: string;
  franchiseDetailData: FranchiseDetailType[];
  franchiseHistoryData: FranchiseHistoryType[];
}

function TeamLayout({ teamId, franchiseDetailData, franchiseHistoryData}: IProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const teamDetails: FranchiseDetailType = franchiseDetailData[0];

  return (
    <Grid container direction="column">
      <Grid container direction="row" margin={5}>
        <Grid item>
            {
              // the margin and spacing are a little wonky without the image. when we finish this feature and
              // can show images remove the 5 margin above and all will be right in the world :D 
              // <img src="/TeamLogo_FDU.png" height="200" width="200" alt="team-logo" />
            }
        </Grid>
        <Grid item>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} justifyContent='center'>
            <Typography component="h1" variant="h5" fontWeight="600">{teamDetails.full_name}</Typography>
            <Typography component="div" variant='body1'>{teamDetails.short_name}, Men&apos;s Basketball</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="History" />
          <Tab label="Seasons" />
        </Tabs>
      </Grid>
      <Grid item>
        <TabPanel value={value} index={0}>
          <TeamHistoryTab teamDetail={teamDetails} teamHistory={franchiseHistoryData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TeamSeasonsTab teamId={teamId} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default TeamLayout;
