import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid } from '@mui/material';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import Header from '../../../compounds/Common/Header';
import StyledTable from '../../../atoms/StyledTable/StyledTable';
import backInTimeInfo from './constants';

const PAGE_TITLE = 'Data Coverage';
const PAGE_SUBTITLE = "AccessElias NCAA Men’s Basketball stats go as far back as the first season listed, unless otherwise noted.";

const headers = [
  'Category',
  'Season',
  'Notes',
];

const rowMap = {
  'Category': 'tournamentName',
  'Season': 'seasonCutoff',
  'Notes': 'notes',
}

function NcaaDataInfo() {
  // const navigate = useNavigate();

  const handleBack = () => {
    // navigate(route)
  };

  return (
    <Dashboard>
      <Container sx={{ py: 2 }} maxWidth="xl">
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems='start'
        >
          <Grid item>
            <Button
              onClick={handleBack}
              sx={{
                fontWeight: 600,
                fontSize: '18px',
                color: (theme: any) => theme.palette.custom.mediumDarkGrey,
              }}
            >
              {'<'} Back
            </Button>
          </Grid>
          <Grid item>
            <Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />
          </Grid>
          <Grid item width='80%'>
            
            <StyledTable
              uniqueRowKey='No'
              headers={headers}
              data={backInTimeInfo}
              dataMap={rowMap}
            />
          </Grid>
        </Grid>
      </Container>
    </Dashboard>
  );
}

export default NcaaDataInfo;
