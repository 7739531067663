import { Grid, TableCell, TableRow } from '@mui/material';
import React from 'react';
import StyledTable from '../../atoms/StyledTable/StyledTable';

const statsHeaders = [
  'Player',
  'GS',
  'MIN',
  'FG',
  'FGA',
  '3P',
  '3PA',
  'FT',
  'FTA',
  'OREB',
  'DREB',
  'REB',
  'AST',
  'STL',
  'BLK',
  'TO',
  'PF',
  'PTS',
];

const statsMap = {
  'Player': 'Player',
  'GS': 'GS',
  'MIN': 'MIN',
  'FG': 'FG',
  'FGA': 'FGA',
  '3P': '3P',
  '3PA': '3PA',
  'FT': 'FT',
  'FTA': 'FTA',
  'OREB': 'OREB',
  'DREB': 'DREB',
  'REB': 'REB',
  'AST': 'AST',
  'STL': 'STL',
  'BLK': 'BLK',
  'TO': 'TO',
  'PF': 'PF',
  'PTS': 'PTS',
};

interface IProps {
  teamName: string;
  coachName: string;
  teamLogoUrl: string;
}

function GamePlayerStatsTable({ teamName, coachName, teamLogoUrl }: IProps) {
  return (
    <Grid container mt={5}>
      <StyledTable
        uniqueRowKey='No'
        headers={statsHeaders}
        data={[]}
        dataMap={statsMap}
        labelHeaderRow={
          <TableRow>
            <TableCell colSpan={12}>
              <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img src={teamLogoUrl} height="50" width="50" alt="team-logo" />
                <h5 style={{ fontSize: '1.4em', margin: 0, }}>{ teamName }</h5>
              </span>
            </TableCell>
            <TableCell colSpan={6} align='right'>
              <span style={{ fontSize: '1.25em', margin: 0, fontWeight: 600, marginRight: 20, }}>Head Coach</span>
              <span style={{ fontSize: '1.15em', margin: 0, }}>{ coachName }</span>
            </TableCell>
          </TableRow>
        }
        headerCellStyles={{
          padding: '4px',
          fontSize: '12px',
        }}
        tableCellStyles={{
          padding: '4px',
          fontSize: '12px',
        }}
      />
    </Grid>
  );
};

export default GamePlayerStatsTable;