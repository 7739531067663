import { GridValidRowModel } from '@mui/x-data-grid/models';
import { DEFAULT_PAGE_SIZE } from '../../../../api/api';
import { GroupedPlayerInstance, PlayerGameResult, PlayerGameTableRow } from '../../../../api/types';

export const generateRowNumber = (
  rowID: number,
  page: number,
  pageSize = DEFAULT_PAGE_SIZE
) => (page === 0 ? rowID + 1 : pageSize * page + rowID + 1);

export const dedupeRows = (allRows: GridValidRowModel) => {
  const ids = allRows.map((o: GridValidRowModel) => o.id);
  const filteredRows = allRows.filter(
    ({ id }: { id: string }, index: number) => !ids.includes(id, index + 1)
  );
  return filteredRows;
};

const calculateStarted = (incoming: boolean) : number => {
  if (incoming !== null) {
    return (incoming) ? 1 : 0;
  }
  return incoming;
};

export const mapUngroupedRows = (results: PlayerGameResult[], page: number): PlayerGameTableRow[] =>
  results.map((row: PlayerGameResult, index: number) => ({
    id: row.id,
    rank: generateRowNumber(index, page),
    player__display_name: row.player.display_name,
    player__player_id: row.player.id,
    age: row.age,
    game__date: row.game.date,
    franchise_season__abbr_3: row.franchise.abbr_3,
    loc: row.home_game ? '' : '@',
    opponent_season__abbr_3: row.opponent.abbr_3,
    result: `${row.game_result} ${row.franchise_score}-${row.opponent_score} ${
      row.game.overtime_played ? 'OT' : ''
    }`,
    game__game_class: row.game.game_class,
    started: calculateStarted(row.started),
    minutes_display: row.minutes_display,
    field_goals_made: row.field_goals_made,
    field_goal_attempts: row.field_goal_attempts,
    field_goal_percentage: Number(row.field_goal_percentage).toFixed(3),
    three_point_field_goals_made: row.three_point_field_goals_made,
    three_point_field_goal_attempts: row.three_point_field_goal_attempts,
    three_point_field_goal_percentage: Number(row.three_point_field_goal_percentage).toFixed(3),
    free_throws_made: row.free_throws_made,
    free_throw_attempts: row.free_throw_attempts,
    free_throw_percentage: Number(row.free_throw_percentage).toFixed(3),
    steals: row.steals,
    blocks: row.blocks,
    turnovers: row.turnovers,
    personal_fouls: row.personal_fouls,
    total_rebounds: row.total_rebounds,
    offensive_rebounds: row.offensive_rebounds,
    defensive_rebounds: row.defensive_rebounds,
    assists: row.assists,
    plus_minus: row.plus_minus,
    points: row.points,
    double_double: row.double_double ? 'T' : 'F',
    triple_double: row.triple_double ? 'T' : 'F',
    franchise_score: row.franchise_score,
    opponent_score: row.opponent_score,
    score_difference: row.game_franchise?.score_difference,
    opening_day: row.opening_day ? 1 : 0,
    points_in_paint: row.points_in_paint,
    points_off_turnovers: row.points_off_turnovers,
    second_chance_points: row.second_chance_points,
    dunks: row.dunks,

    // the following fields will not display but are needed for rendering
    // other things
    franchise_id: row.franchise.franchise_id,
    opponent_id: row.opponent.franchise_id,
    game_id: row.game.id,
  }));

export const getGameDateFromID = (gameID: number) => {
  if (!gameID) return '';
  // Converts the GameID which is in the YYYYMMMDDGG format to game date format YYYY-MM-DD
  const date = gameID.toString().slice(0, -2);
  const formattedDate = date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
  return formattedDate;
};

export const mapPlayerRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    player_id: row.player_id,
  }));

export const mapPlayerSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    season: row.season,
    player_id: row.player_id,
  }));  

export const mapPlayerFranchiseRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    franchise_name: row.franchise?.full_name,
    player_id: row.player_id,
  }));

export const mapPlayerOpponentRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    opponent_name: row.opponent?.full_name,
    player_id: row.player_id,
  }));

export const mapPlayerFranchiseSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    franchise_name: row.franchise?.full_name,
    season: row.season,
    player_id: row.player_id,
  }));

export const mapPlayerOpponentSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    opponent_name: row.opponent?.full_name,
    season: row.season,
    player_id: row.player_id,
  }));  

export const mapRows = (
  results: PlayerGameResult[] | GroupedPlayerInstance[],
  groupByColumn: string,
  page: number
) => {
  switch (groupByColumn) {
    case 'player':
      return mapPlayerRows(results as GroupedPlayerInstance[], page);
    case 'player-season':
      return mapPlayerSeasonRows(results as any, page);
    case 'player-franchise':
      return mapPlayerFranchiseRows(results as any, page);
    case 'player-opponent':
      return mapPlayerOpponentRows(results as any, page);
    case 'player-franchise-season':
      return mapPlayerFranchiseSeasonRows(results as any, page);
    case 'player-opponent-season':
      return mapPlayerOpponentSeasonRows(results as any, page);
    default:
      return mapUngroupedRows(dedupeRows(results) as PlayerGameResult[], page);
  };
};
