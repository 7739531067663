import React from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FinderSelectOptionType } from '../../pages/Nba/PlayerGameFinder/constants';

const selectColor = '#f9fafb';

// note: if you use proper camel casing the linter will complain about the variable being called textcolor
const StyledSelect = styled(Select)((props: { textcolor: string, backgroundcolor: string }) => ({
  backgroundColor: props.backgroundcolor,
  borderRadius: '8px 8px 8px 8px',
  color: props.textcolor,
  height: '36px',
  lineHeight: '20px',
  "&.MuiOutlinedInput-root": {
    "&.Mui-disabled fieldset": {
      borderColor: selectColor,
    },
    "&.Mui-focused": {
      backgroundColor: '#eff8ff',
      borderColor: '#eff8ff',
    },
    "& fieldset": {
      borderColor: selectColor
    },
    "&:hover fieldset": {
      borderColor: selectColor
    },
    "&.Mui-focused fieldset": {
      borderColor: selectColor
    }
  },
  '& .MuiSvgIcon-root': {
    color: props.textcolor,
 }
}));

interface FinderSelectProps {
  label: string;
  value: string | null;
  options: FinderSelectOptionType[];
  handleSelection: (selection: string) => void | null;
  disabled?: boolean;
  selectStyleOverrides?: {
    formControl?: object,
    select?: object,
  };
}

function FinderSelect({
  label,
  value,
  options,
  handleSelection,
  disabled=false,
  selectStyleOverrides={},
}: FinderSelectProps) {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selection = event.target.value as string;
    handleSelection(selection);
  };
  const backgroundColor = value !== options[0].id ? '#EFF8FF' : '#f9fafb';
  const textColor = value !== options[0].id ? '#175CD3' : '#101828';

  return (
    <FormControl size="small" variant="outlined" sx={selectStyleOverrides.formControl}>
      <StyledSelect
        disabled={disabled}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          variant: 'menu'
        }}
        onChange={handleChange}
        renderValue={() => {
          const selectedOption = options.find(({ id: v }) => v === value );
          return `${label}: ${selectedOption?.label || ''}`
        }}
        value={value}
        textcolor={textColor}
        backgroundcolor={backgroundColor}
        sx={selectStyleOverrides.select}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}

export default FinderSelect;
