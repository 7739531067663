import React from 'react';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import GameLayout from '../../../compounds/GameLayout/GameLayout';

function NcaaMbbGame() {
  

  return (
    <Dashboard>
      <GameLayout />
    </Dashboard>
  );
}

export default NcaaMbbGame;
