import React from 'react';
import { Grid } from '@mui/material';
import BoxScoreTeamRow from './BoxScoreTeamRow';
import BoxScoreSplitStats from './BoxScoreSplitStats';
import BoxScoreHeader from './BoxScoreHeader';

function BoxScore() {
  return (
    <>
      <BoxScoreHeader />
      <Grid container direction='row' paddingY={3}>
        <Grid item xs={6}>
          <Grid container direction='column'>
            <Grid item>
              <BoxScoreTeamRow
                teamName='Duke'
                teamRecord='21-5, 14-3 ACC'
                teamScore={64}
                teamLogoUrl='/TeamLogo_FDU.png'
                teamWin={false}
              />
            </Grid>
            <Grid item>
              <BoxScoreTeamRow
                teamName='Kansas'
                teamRecord='19-10, 14-3 Big 12'
                teamScore={69}
                teamLogoUrl='/TeamLogo_FDU.png'
                teamWin
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <BoxScoreSplitStats />
        </Grid>
      </Grid>
    </>
  );
}

export default BoxScore;
