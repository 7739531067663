import { Grid } from '@mui/material';
import React from 'react';
import { ModalFilterType } from '../../types/modal';
import { EqualitySignType } from '../../utils/stats/stats-utils';
import { EliasEqualityButtons } from '../EliasEqualityButtons/EliasEqualityButtons';
import EliasNumericInput from '../EliasInput/EliasNumericInput';
import ModalCheckbox from '../ModalCheckbox/ModalCheckbox';

interface IProps {
  filter: ModalFilterType;

  selected: boolean;
  selectedEquality: string;
  inputValue: string;

  onCheckBoxChange?: (
    id: string,
    checked: boolean,
    chip: string,
  ) => void;
  onInputValueChange?: (
    option: string,
    value: string,
    equalityOption: string,
    chipAbbr: string,
  ) => void;
};


function ModalEqualityNumeric({
  filter,

  selected = false,
  selectedEquality,
  inputValue,

  onCheckBoxChange = () => {},
  onInputValueChange = () => {},
}: IProps) {

  const handleCheckBoxChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    onCheckBoxChange(filter.id, checked, filter.chip)
  };

  const handleEqualityButtonClick = (
    sign: EqualitySignType,
  ) => {
    onInputValueChange(filter.id, inputValue, sign, filter.chip);
  };

  const handleInputChange = (value: string) => {
    onInputValueChange(filter.id, value, selectedEquality, filter.chip);
  };

  return (
    <Grid container key={`${filter.id}-equality-row`}>
      <Grid item xs={8}>
        <ModalCheckbox
          key={`${filter.id}-option`}
          label={filter.label}
          subLabel={filter.subLabel}
          labelPlacement="end"
          selected={selected}
          onChange={handleCheckBoxChange}
        />
      </Grid>
      <Grid item xs={4} display='flex' alignItems='center' justifyContent='end'>
        <EliasEqualityButtons
          selected={selectedEquality}
          onButtonClick={handleEqualityButtonClick}
          filterId={filter.id}
          filterAbbreviation={filter.label}
        />
        <EliasNumericInput
          id={filter.id}
          value={inputValue}
          onChange={handleInputChange}
          ariaLabel={`${filter.label}-label`}
        />
      </Grid>
    </Grid>
  );
}

export default ModalEqualityNumeric;