import { useQuery } from '@tanstack/react-query';
import { useSignOut } from 'react-auth-kit';
import { 
  fetchFranchiseDetail, 
  fetchFranchiseHistory, 
  fetchFranchiseSeason, 
  fetchPlayerDetail, 
  fetchPlayers, 
  fetchPlayerStats, 
  fetchTeams, 
  fetchVenues,
  fetchGolfTournaments,
  fetchGolfCourses,
  fetchTournamentDetail, 
  fetchTournamentResults,
  fetchCourseDetail,
  fetchCourseStats
} from '../../api/api';
import { FranchiseDetailData, FranchiseHistoryData, FranchiseSeasonData, PlayerData, PlayerProfileData, TeamData, VenueData, TournamentDetailData, TournamentResultsData } from '../../api/types';
import { PlayerProfileStatsType } from '../types/player';

const formatPlayerFilter = (filter: string) => {
  const formattedFilter = filter.trim().replace(' ', '+');
  return `name__istartswith=${formattedFilter}`;
};

export const useFetchPlayers = (filter: string, apiUrl: string) => {
  const formattedString = formatPlayerFilter(filter);
  const signOut = useSignOut();
  return useQuery<PlayerData>(
    ['PlayerInfo', formattedString],
    () => fetchPlayers(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const formatTeamOrOpponentFilter = (filter: string) => {
  const formattedFilter = filter.trim().replace(' ', '+');
  return `full_name__icontains=${formattedFilter}`;
};

export const useFetchTeams = (filter: string, apiUrl: string) => {
  const formattedString = formatTeamOrOpponentFilter(filter);
  const signOut = useSignOut();
  return useQuery<TeamData>(
    ['TeamInfo', formattedString],
    () => fetchTeams(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchGolfTournaments = (filter: string, apiUrl: string) => {
  const formattedString = formatTeamOrOpponentFilter(filter);
  const signOut = useSignOut();
  return useQuery<TeamData>(
    ['GolfTournamentInfo', formattedString],
    () => fetchGolfTournaments(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchGolfCourses = (filter: string, apiUrl: string) => {
  const formattedString = formatTeamOrOpponentFilter(filter);
  const signOut = useSignOut();
  return useQuery<TeamData>(
    ['GolfCourseInfo', formattedString],
    () => fetchGolfCourses(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchOpponents = (filter: string, apiUrl: string) => {
  const formattedString = formatTeamOrOpponentFilter(filter);
  const signOut = useSignOut();
  return useQuery<TeamData>(
    ['OpponentInfo', formattedString],
    () => fetchTeams(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const formatVenueFilter = (filter: string) => {
  const formattedFilter = filter.trim().replace(' ', '+');
  return `venue_name__icontains=${formattedFilter}`;
};

export const useFetchVenues = (filter: string, apiUrl: string) => {
  const formattedString = formatVenueFilter(filter);
  const signOut = useSignOut();
  return useQuery<VenueData>(
    ['VenueInfo', formattedString],
    () => fetchVenues(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchFranchiseHistory = (franchiseId: string, apiUrl: string) => {
  const formattedString = `franchise=${franchiseId}&ordering=-season`;
  const signOut = useSignOut();
  return useQuery<FranchiseHistoryData>(
    ['FranchiseHistoryInfo', formattedString],
    () => fetchFranchiseHistory(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

// the ids in the franchise profile table do NOT map to the franchise id used everywhere else
// so send a filter param and select first returned object
export const useFetchFranchiseDetail = (franchiseId: string, apiUrl: string) => {
  const formattedString = `franchise=${franchiseId}`;
  const signOut = useSignOut();
  return useQuery<FranchiseDetailData>(
    ['FranchiseDetailInfo', formattedString],
    () => fetchFranchiseDetail(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchFranchiseSeason = (franchiseId: string, season: string, apiUrl: string) => {
  const formattedString = `franchise=${franchiseId}&season=${season}`;
  const signOut = useSignOut();
  return useQuery<FranchiseSeasonData>(
    ['FranchiseSeasonInfo', formattedString],
    () => fetchFranchiseSeason(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchPlayerDetail = (playerId: string, apiUrl: string) => {
  const url = `${apiUrl}/${playerId}`;
  const signOut = useSignOut();
  return useQuery<PlayerProfileData>(
    ['PlayerDetailInfo', playerId],
    () => fetchPlayerDetail(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchPlayerStats = (playerId: string, apiUrl: string) => {
  const url = `${apiUrl}/${playerId}`;
  const signOut = useSignOut();
  return useQuery<PlayerProfileStatsType>(
    ['PlayerStatsInfo', playerId],
    () => fetchPlayerStats(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchTournamentDetail = (tournamentId: string, apiUrl: string) => {
  const url = `${apiUrl}/${tournamentId}`;
  const signOut = useSignOut();
  return useQuery<TournamentDetailData>(
    ['TournamentDetailInfo', tournamentId],
    () => fetchTournamentDetail(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchTournamentResults = (tournamentId: string, apiUrl: string) => {
  const url = `${apiUrl}/${tournamentId}`;
  const signOut = useSignOut();
  return useQuery<TournamentResultsData>(
    ['TournamentResultsInfo', tournamentId],
    () => fetchTournamentResults(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchCourseDetail = (courseId: string, apiUrl: string) => {
  const url = `${apiUrl}/${courseId}`;
  const signOut = useSignOut();
  return useQuery<CourseDetailData>(
    ['CourseDetailInfo', courseId],
    () => fetchCourseDetail(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchCourseStats = (courseId: string, apiUrl: string) => {
  const url = `${apiUrl}/${courseId}`;
  const signOut = useSignOut();
  return useQuery<CourseStatsData>(
    ['CourseStatsInfo', courseId],
    () => fetchCourseStats(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};