import { ModalFilterCategoriesType } from "../../types/modal";

const CURRENT_SEASON = '2021-22';

export const advancedModalFilterCategories: ModalFilterCategoriesType[] = [];

export const gameModalFilterCategories: ModalFilterCategoriesType[] = [
  {
    category: 'game_types',
    categoryLabel: 'Game Types',
    filters: [
      {
        id: 'reg',
        label: 'REG: Regular Season',
        subLabel: 'Stats available since 2005-06',
        searchTerms: ['REG: Regular Season'],
        filterType: 'checkbox',
        chip: 'Regular Season'
      },
      {
        id: 'ps',
        label: 'Tournaments',
        subLabel: 'Includes conference and postseason tournaments',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Any Tourney'
      },
    ]
  },
  {
    category: 'ncaa_tournament',
    categoryLabel: 'NCAA Tournament',
    filters: [
      {
        id: 'ncaa_tournament',
        label: 'NCAA Tournament',
        subLabel: 'Includes all rounds. Stats availability range from the 1938-39 to 1986-87 seasons',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Tourney'
      },
      {
        id: 'third_place_game',
        label: 'Third-Place Game',
        subLabel: 'Played from the year 1946 through 1981',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Third-Place Game'
      },
      {
        id: 'play_in_round',
        label: 'Play-In Round',
        subLabel: 'Played in years 1983, 1984, and since 2001',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Play-In Round'
      },
      {
        id: 'round_of_64',
        label: 'Round of 64',
        subLabel: 'Round of 64 (since year 1985) or equivalent (since 1979)',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Round 1'
      },
      {
        id: 'round_of_32',
        label: 'Round of 32',
        subLabel: 'Round of 32 (since year 1975) or equivalent (since 1962)',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Round 2'
      },
      {
        id: 'sweet_sixteen',
        label: 'Sweet 16',
        subLabel: 'Since year 1951',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Sweet 16'
      },
      {
        id: 'elite_eight',
        label: 'Elite Eight',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Elite Eight'
      },
      {
        id: 'final_four',
        label: 'Final Four',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Final Four'
      },
      {
        id: 'championship',
        label: 'Championship',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Championship'
      },
    ]
  },
  {
    category: 'conference_tournaments',
    categoryLabel: 'Conference Tournaments',
    filters: [
      {
        id: 'any_conference_tournament',
        label: 'Any Conference Tournament',
        subLabel: 'Includes all conference tournaments and all rounds',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Any Conf. Tourney'
      },
      {
        id: 'all_rounds',
        label: 'Any Round',
        subLabel: 'Includes all rounds for any selected conference tournaments',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Conf. Tourney Any Round'
      },
      {
        id: 'quarterfinals',
        label: 'Quarterfinals',
        subLabel: 'Includes Quarterfinals for any selected conference tournaments',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Conf. Tourney Quarterfinals'
      },
      {
        id: 'semifinals',
        label: 'Semifinals',
        subLabel: 'Includes Semifinals for any selected conference tournaments',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Conf. Tourney Semifinals'
      },
      {
        id: 'finals',
        label: 'Finals',
        subLabel: 'Includes Finals for any selected conference tournaments',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Conf. Tourney Finals'
      },
      {
        id: 'a_ten_tournament',
        label: 'A-Ten Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'A-Ten Tourney'
      },
      {
        id: 'a_sun_tournament',
        label: 'A-Sun Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'A-Sun Tourney'
      },
      {
        id: 'acc_tournament',
        label: 'ACC Tournament',
        subLabel: 'Stats available since 1953-54',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'ACC Tourney'
      },
      {
        id: 'america_east_tournament',
        label: 'America East Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'America East Tourney'
      },
      {
        id: 'american_athletic_tournament',
        label: 'American Athletic Tournament',
        subLabel: 'Stats available since 2013-14',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'American Athletic Tourney'
      },
      {
        id: 'big_eight_tournament',
        label: 'Big Eight Tournament',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Big Eight Tourney'
      },
      {
        id: 'big_east_tournament',
        label: 'Big East Tournament',
        subLabel: 'Stats available since 1979-80',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Big East Tourney'
      },
      {
        id: 'big_sky_tournament',
        label: 'Big Sky Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Big Sky Tourney'
      },
      {
        id: 'big_south_tournament',
        label: 'Big South Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Big South Tourney'
      },
      {
        id: 'big_ten_tournament',
        label: 'Big Ten Tournament',
        subLabel: 'Stats available since 1997-98',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Big Ten Tourney'
      },
      {
        id: 'big_west_tournament',
        label: 'Big West Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Big West Tourney'
      },
      {
        id: 'big_twelve_tournament',
        label: 'Big XII Tournament',
        subLabel: 'Stats available since 1996-97',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Big XII Tourney'
      },
      {
        id: 'c_usa_tournament',
        label: 'C-USA Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'C-USA Tourney'
      },
      {
        id: 'caa_tournament',
        label: 'CAA Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'CAA Tourney'
      },
      {
        id: 'great_west_tournament',
        label: 'Great West Tournament',
        subLabel: 'Stats available since 2009-10',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Great West Tourney'
      },
      {
        id: 'horizon_tournament',
        label: 'Horizon Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Horizon Tourney'
      },
      {
        id: 'ivy_tournament',
        label: 'Ivy Tournament',
        subLabel: 'Stats available since 2016-17',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Ivy Tourney'
      },
      {
        id: 'maac_tournament',
        label: 'MAAC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'MAAC Tourney'
      },
      {
        id: 'mac_tournament',
        label: 'MAC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'MAC Tourney'
      },
      {
        id: 'meac_tournament',
        label: 'MEAC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'MEAC Tourney'
      },      
      {
        id: 'mountain_west_tournament',
        label: 'Mountain West Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Mountain West Tourney'
      },
      {
        id: 'mvc_tournament',
        label: 'MVC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'MVC Tourney'
      },
      {
        id: 'nec_tournament',
        label: 'NEC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NEC Tourney'
      },
      {
        id: 'ovc_tournament',
        label: 'OVC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'OVC Tourney'
      },
      {
        id: 'pac_twelve_tournament',
        label: 'PAC-12 Tournament (FKA: PAC-10)',
        subLabel: 'Stats available since 1986-87',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'PAC-12 Tourney'
      },
      {
        id: 'patriot_league_tournament',
        label: 'Patriot League Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Patriot League Tourney'
      },
      {
        id: 'sec_tournament',
        label: 'SEC Tournament',
        subLabel: 'Stats available since 1998-89',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'SEC Tourney'
      },
      {
        id: 'southern_tournament',
        label: 'Southern Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Southern Tourney'
      },
      {
        id: 'southland_tournament',
        label: 'Southland Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Southland Tourney'
      },
      {
        id: 'summit_tournament',
        label: 'Summit Tournament (FKA: Mid-Continent)',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Summit Tourney'
      },
      {
        id: 'sun_belt_tournament',
        label: 'Sun Belt Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Sun Belt Tourney'
      },
      {
        id: 'swac_tournament',
        label: 'SWAC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'SWAC Tourney'
      },
      {
        id: 'wac_tournament',
        label: 'WAC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'WAC Tourney'
      },
      {
        id: 'wcc_tournament',
        label: 'WCC Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'WCC Tourney'
      },
    ]
  },
  {
    category: 'other_postseason_tournaments',
    categoryLabel: 'Other Postseason Tournaments',
    filters: [
      {
        id: 'basketball_classic',
        label: 'Basketball Classic',
        subLabel: 'Stats available for 2021-22',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Basketball Classic'
      },
      {
        id: 'cbi_tournament',
        label: 'CBI Tournament',
        subLabel: 'Stats available since 2007-08',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'CBI Tourney'
      },
      {
        id: 'cit_tournament',
        label: 'CIT Tournament',
        subLabel: 'Stats available since 2008-09',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'CIT Tourney'
      },
      {
        id: 'nit_tournament',
        label: 'NIT Tournament',
        subLabel: 'Stats available since 2002-03',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NIT Tourney'
      },
    ]
  },
  {
    category: 'season',
    categoryLabel: 'Season Filters',
    filters: [
      {
        id: 'season_group',
        label: 'Current Season',
        subLabel: '',
        searchTerms: [''],
        filterType: 'radio',
        chip: 'season',
        radioGroup: [
          {
            id: 'current_season',
            label: 'Current Season',
            subLabel: 'By default, incudes regular season, conference and postseason tournaments',
            value: CURRENT_SEASON,
            type: 'default',
            chip: 'Current Season',
          },
          {
            id: 'season_range',
            label: 'Season Range',
            subLabel: '',
            value: '',
            type: 'custom_season_range',
            chip: '[INPUT_VALUE] - [INPUT_END_VALUE]',
          },
        ]
      },
    ]
  },
]
