import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LogIn from '../../atoms/Auth/LogIn'
import Home from '../../pages/Home';

import NbaPlayerStreakFinderResults from '../../pages/Nba/PlayerStreakFinder/PlayerStreakFinderResults';
import NbaPlayerStreakFinderSearch from '../../pages/Nba/PlayerStreakFinder/PlayerStreakFinderSearch';
import NbaPlayerGameFinderResults from '../../pages/Nba/PlayerGameFinder/PlayerGameFinderResults';
import NbaPlayerGameFinderSearch from '../../pages/Nba/PlayerGameFinder/PlayerGameFinderSearch';
import NbaPlayerSpanFinderSearch from '../../pages/Nba/PlayerSpanFinder/PlayerSpanFinderSearch';
import NbaPlayerSpanFinderResults from '../../pages/Nba/PlayerSpanFinder/PlayerSpanFinderResults';

import NcaaMbbPlayerStreakFinderResults from '../../pages/NcaaMbb/PlayerStreakFinder/PlayerStreakFinderResults';
import NcaaMbbPlayerStreakFinderSearch from '../../pages/NcaaMbb/PlayerStreakFinder/PlayerStreakFinderSearch';
import NcaaMbbPlayerGameFinderResults from '../../pages/NcaaMbb/PlayerGameFinder/PlayerGameFinderResults';
import NcaaMbbPlayerGameFinderSearch from '../../pages/NcaaMbb/PlayerGameFinder/PlayerGameFinderSearch';
import NcaaMbbPlayerSpanFinderSearch from '../../pages/NcaaMbb/PlayerSpanFinder/PlayerSpanFinderSearch';
import NcaaMbbPlayerSpanFinderResults from '../../pages/NcaaMbb/PlayerSpanFinder/PlayerSpanFinderResults';
import NcaaMbbTeam from '../../pages/NcaaMbb/Team/Team';
import NcaaMbbPlayer from '../../pages/NcaaMbb/Player/Player';
import NcaaMbbGame from '../../pages/NcaaMbb/Game/Game';
import NcaaDataInfo from '../../pages/NcaaMbb/DataInfo/DataInfo';

import GolfGolfer from '../../pages/Golf/Golfer/Golfer';
import GolfTournamentHistory from '../../pages/Golf/GolfTournamentHistory/GolfTournamentHistory';
import GolfTournament from '../../pages/Golf/GolfTournament/GolfTournament';
import GolfCourse from '../../pages/Golf/GolfCourse/GolfCourse';
import GolferTournamentFinderSearch from '../../pages/Golf/GolferTournamentFinder/GolferTournamentFinderSearch';
import GolferTournamentFinderResults from '../../pages/Golf/GolferTournamentFinder/GolferTournamentFinderResults';
import GolferRoundFinderSearch from '../../pages/Golf/GolferRoundFinder/GolferRoundFinderSearch';
import GolferRoundFinderResults from '../../pages/Golf/GolferRoundFinder/GolferRoundFinderResults';


import ROUTER_URLS from './urls';
import PrivateRoute from './PrivateRoute';
import {
  NbaPlayerGameFinderContextProvider,
  NbaPlayerSpanFinderContextProvider,
  NbaPlayerStreakFinderContextProvider,
  NcaaMbbPlayerGameFinderContextProvider,
  NcaaMbbPlayerSpanFinderContextProvider,
  NcaaMbbPlayerStreakFinderContextProvider,
  GolfGolferTournamentFinderContextProvider,
  GolfGolferRoundFinderContextProvider
} from '../../contexts/FilterContext';


export const ROUTES_CONFIG = [
  {
    path: ROUTER_URLS.login,
    element: <LogIn />
  },
  {
    path: ROUTER_URLS.home,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <Home />
      </PrivateRoute>
    )
  },
  {
    element: <NbaPlayerGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.playerGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NbaPlayerGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: `${ROUTER_URLS.nba.playerGameFinderResults}/:searchId`,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NbaPlayerGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NbaPlayerStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.playerStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NbaPlayerStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.nba.playerStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NbaPlayerStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NbaPlayerSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.playerSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NbaPlayerSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.nba.playerSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NbaPlayerSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    element: <NcaaMbbPlayerGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.playerGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NcaaMbbPlayerGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.playerGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NcaaMbbPlayerGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaMbbPlayerStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.playerStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NcaaMbbPlayerStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.playerStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NcaaMbbPlayerStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaMbbPlayerSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.playerSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NcaaMbbPlayerSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.playerSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <NcaaMbbPlayerSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    element: <GolfGolferTournamentFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.golf.golferTournamentFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <GolferTournamentFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.golf.golferTournamentFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <GolferTournamentFinderResults />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    element: <GolfGolferRoundFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.golf.golferRoundFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <GolferRoundFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.golf.golferRoundFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <GolferRoundFinderResults />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: ROUTER_URLS.ncaambb.team,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaMbbTeam />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaambb.player,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaMbbPlayer />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaambb.game,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaMbbGame />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaambb.dataInfo,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaDataInfo />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.golf.golfer,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <GolfGolfer />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.golf.golfTournamentHistory,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <GolfTournamentHistory />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.golf.golfTournament,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <GolfTournament />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.golf.golfCourse,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <GolfCourse />
      </PrivateRoute>
    )
  },
];

export const ROUTES = createBrowserRouter(ROUTES_CONFIG);
