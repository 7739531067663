import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { TableCell, TableRow } from '@mui/material';
import FactCard from '../../atoms/FactCard/FactCard';
import StyledTable from '../../atoms/StyledTable/StyledTable';
import PillButtonGroup from '../../atoms/PillButtonGroup/PillButtonGroup';
import { PlayerProfileStatsType, PlayerProfileType } from '../../types/player';

interface IProps {
  playerDetails: PlayerProfileType;
  statsData: PlayerProfileStatsType;
};

const seasonPerGameHeaders = [
  'Season',
  'School',
  'MP',
  'PTS',
  'OREB',
  'DREB',
  'REB',
  'AST',
  'BLK',
  'STL',
  'FG%',
  '3P%',
  'FT%',
];

const seasonPerGameMap = {
  'Season': 'season',
  'School': 'schoolName',
  'MP': 'minutesPlayed',
  'PTS': 'points',
  'OREB': 'offensiveRebounds',
  'DREB': 'defensiveRebounds',
  'REB': 'rebounds',
  'AST': 'assists',
  'BLK': 'blocks',
  'STL': 'steals',
  'FG%': 'fgPct',
  '3P%': 'threePntFgPct',
  'FT%': 'ftPct',
};

const mapStatsData = (data: PlayerProfileStatsType) => {
  const seasonData = data.seasons;

  return Object.keys(seasonData).map((key: string) => {
    const seasonRow = seasonData[key];
    const perGameRow = seasonRow.per_game;

    return {
      id: key,  
      season: key,
      schoolName: seasonRow.franchise.name,
      minutesPlayed: perGameRow.minutes,
      points: perGameRow.points,
      offensiveRebounds: perGameRow.offensive_rebounds,
      defensiveRebounds: perGameRow.defensive_rebounds,
      rebounds: perGameRow.total_rebounds,
      assists: perGameRow.assists,
      blocks: perGameRow.blocks,
      steals: perGameRow.steals,
      fgPct: perGameRow.field_goal_percentage,
      threePntFgPct: perGameRow.three_point_field_goal_percentage,
      ftPct: perGameRow.free_throw_percentage,
    };
  });
};

function PlayerLayout({ playerDetails, statsData }: IProps) {
  const [seasonTab, setSeasonTab] = React.useState(0);
  const [perGameTab, setPerGameTab] = React.useState(0); 

  const handleSeasonTabChange = (tab: number) => {
    setSeasonTab(tab);
  };

  const handlePerGameTabChange = (tab: number) => {
    setPerGameTab(tab);
  };

  const currentTeam = playerDetails.franchises.find((franchise) => franchise.current);
  const tableData = mapStatsData(statsData);

  return (
    <Grid container direction="column" sx={{ margin: 0 }}>
      <Grid container direction="row" marginTop={5}>
        <Grid item>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} justifyContent='center'>
            <Typography component="h1" variant="h5" fontWeight="600">{playerDetails.name} {playerDetails.uniform_number}</Typography>
            <Typography component="div" variant='body1'>{currentTeam ? currentTeam.name : 'No Active Team'}, Men&apos;s Basketball</Typography>
          </Box>
        </Grid>
      </Grid>  
      <Grid container direction="row" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 3 }}>
        <Grid item xs={3}>
          <FactCard label='Position' values={[playerDetails.position]} />
          <FactCard label='Height' values={[playerDetails.height]} />
          <FactCard label='Weight' values={[playerDetails.weight]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Hometown' values={[playerDetails.home_town]} />
          <FactCard label='High School' values={[playerDetails.highschool]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='School(s)' values={playerDetails.franchises.map((s: any) => s.name)} />
          <FactCard label='Class' values={[playerDetails.class]} />
        </Grid>
      </Grid>

      <Grid container mt={5} marginRight={5}>
        <Grid item xs={12}>
          <StyledTable
            uniqueRowKey='id'
            headers={seasonPerGameHeaders}
            data={tableData}
            dataMap={seasonPerGameMap}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={14}>
                  <h5 style={{ display: 'inline-block', fontSize: '1.4em', marginRight: 3 }}>Stats</h5>
                  <PillButtonGroup selected={seasonTab} values={['Season', 'Conference']} onChange={handleSeasonTabChange} />
                  <PillButtonGroup selected={perGameTab} values={['Per Game', 'Totals']} onChange={handlePerGameTabChange} />
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PlayerLayout;
