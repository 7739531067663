import React from 'react';
import Typography from '@mui/material/Typography';

interface IProps {
  label: string;
  values: string[];
  useLineBreakForValues: boolean;
}

function FactCard({ label, values, useLineBreakForValues=false }: IProps) {
  const filteredValues = values.filter((f) => f);
  if (filteredValues.length === 0) {
    return <div />;
  }
  console.log("from FactCard");
  console.log(useLineBreakForValues);
  return (
    <>
      <Typography component="div" variant='body1' fontWeight="600" mt={2}>{label}</Typography>
      { filteredValues.map((value: string) => 
          <Typography key={value} component="div" variant='body1' mt={1}>
            {value}
          </Typography>
      )}
    </>
  );
}

export default FactCard;
