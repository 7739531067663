import React from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import API_URLS from '../../../../api/api_urls';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import GolfCourseLayout from '../../../compounds/GolfCourseLayout/GolfCourseLayout';
import { useFetchCourseDetail, useFetchCourseStats } from '../../../hooks/useFetch';

function GolfCourse() {
  const { courseId } = useParams();

  const apiUrl = API_URLS.golf;

  const { data: courseDetailData, isLoading: isProfileLoading, error: profileError } = useFetchCourseDetail(courseId || '', apiUrl.course_detail);
  const { data: courseStatsData, isLoading: isStatsLoading, error: statsError } = useFetchCourseStats(courseId || '', apiUrl.course_stats);

  if (isProfileLoading || isStatsLoading) {
    return <span>Loading...</span>
  }

  if (profileError && profileError instanceof Error) {
    return <span>Error: {profileError.message}</span>
  }

  if (statsError && statsError instanceof Error) {
    return <span>Error: {statsError.message}</span>
  }

  if (!courseDetailData || !courseStatsData) {
    return <span>Error: Api failed to respond</span>
  }

  return (
    <Dashboard>
      <Container sx={{ py: 2 }} maxWidth="xl">
        <GolfCourseLayout courseDetails={courseDetailData} courseStatsData={courseStatsData} />
      </Container>
    </Dashboard>
  );
}

export default GolfCourse;
