import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { TableCell, TableRow } from '@mui/material';
import FactCard from '../../atoms/FactCard/FactCard';
import StyledTable from '../../atoms/StyledTable/StyledTable';
import { PlayerProfileStatsType, PlayerProfileType } from '../../types/player';

interface IProps {
  tournamentDetails: PlayerProfileType;
  tournamentResultsData: PlayerProfileStatsType;
};

const golferTournamentHeaders = [
  'Pos',
  'Player',
  'Total',
  'RD1',
  'RD2',
  'RD3',
  'RD4',
  'Total Score',
  'Prize Money',
];

const golferTournamentMap = {
  'Pos': 'position',
  'Player': 'golfer',
  'Total': 'scoreToPar',
  'RD1': 'roundOneScore',
  'RD2': 'roundTwoScore',
  'RD3': 'roundThreeScore',
  'RD4': 'roundFourScore',
  'Total Score': 'totalScore',
  'Prize Money': 'prizeMoney',
};


const mapStatsData = (data: PlayerProfileStatsType) => {
  const golferData = data.golfers;

  return Object.keys(golferData).map((key: string) => {
    const golferRow = golferData[key];
    return {
      id: key,
      position: golferRow.position,
      golfer: golferRow.golfer,
      scoreToPar: golferRow.scoreToPar,
      totalScore: golferRow.totalScore,
      roundOneScore: golferRow.roundOneScore,
      roundTwoScore: golferRow.roundTwoScore,
      roundThreeScore: golferRow.roundThreeScore,
      roundFourScore: golferRow.roundFourScore,
      prizeMoney: golferRow.prizeMoney,
    };
  });
};

function GolfTournamentLayout({ tournamentDetails, tournamentResultsData }: IProps) {
  const tableData = mapStatsData(tournamentResultsData);
  return (
    <Grid container direction="column" sx={{ margin: 0 }}>
      <Grid container direction="row" marginTop={5}>
        <Grid item>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} justifyContent='center'>
            <Typography component="h1" variant="h3" fontWeight="600">{tournamentDetails.name}</Typography>
            <Typography component="div" variant='body1'>PGA Tour - Major Tournament</Typography>
          </Box>
        </Grid>
      </Grid>  
      <Grid container direction="row" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 3 }}>
        <Grid item xs={3}>
          <FactCard label='Course' values={[tournamentDetails.course]} />
          <FactCard label='Par' values={[tournamentDetails.par]} />
          <FactCard label='Length' values={[tournamentDetails.length]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Dates Held' values={[tournamentDetails.dates]} />
          <FactCard label='Total Purse' values={[tournamentDetails.purse]} />
          <FactCard label='Defending Champion' values={[tournamentDetails.defending_champion]} />
        </Grid>
      </Grid>

      <Grid container mt={5} marginRight={5}>
        <Grid item xs={12}>
          <StyledTable
            uniqueRowKey='id'
            headers={golferTournamentHeaders}
            data={tableData}
            dataMap={golferTournamentMap}
            linkableItems={['year', 'golfCourse', 'winner', 'golfer', 'tournamentName']}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={14}>
                  <h5 style={{ display: 'inline-block', fontSize: '1.4em', marginRight: 3 }}>Tournament Results</h5>
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GolfTournamentLayout;