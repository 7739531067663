import API_URLS from './api_urls';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PLAYER_GAME_ORDERING = '-game_date';
export const DEFAULT_GOLFER_TOURNAMENT_ORDERING = '-tournament_date';
export const DEFAULT_GOLFER_ROUND_ORDERING = '-tournament_date';


const SERVER_ERROR_MESSAGE = "Server error - Please contact support.";
const TIMEOUT_ERROR_MESSAGE = "Your search has taken longer than the allotted time to complete. Please narrow your filter criteria and try again.";

const handleErrors = (
  response: Response,
  signOut: () => boolean,
) => {
  if (response.status === 401) {
    signOut();
  } else if (response.status === 408) { // handle request timeout
    throw new Error(TIMEOUT_ERROR_MESSAGE);
  } else if (response.status === 429) { // too many requests
    throw new Error(SERVER_ERROR_MESSAGE);
  } else if (response.status === 500) { // server error
    throw new Error(SERVER_ERROR_MESSAGE);
  } else if (response.status === 502) { // bad gateway
    throw new Error(TIMEOUT_ERROR_MESSAGE);
  } else if (response.status === 503) { // service unavailable
    throw new Error(SERVER_ERROR_MESSAGE);
  } else if (response.status === 504) { // gateway timeout
    throw new Error(TIMEOUT_ERROR_MESSAGE);
  } else {
    throw new Error('Network response was not ok');
  }
};

export const baseFetchPlayerGameStats = async (
  endpoint: string,
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => {
  const offset = page * DEFAULT_PAGE_SIZE;
  const params: Array<string> = [];

  if (filters.length > 0) {
    params.push(...filters);
  }
  if (sortBy) {
    params.push(`ordering=${sortBy}`);
  }
  if (offset > 0) {
    params.push(`offset=${offset}`);
  }

  if (uuid && uuid.length > 0) {
    params.push(`search_uuid=${uuid}`);
  }

  const url = `${endpoint}?${params.join('&')}`;
  const response = await fetch(url);
  if (!response.ok) {
    handleErrors(response, signOut);
  }
  return response.json();
};

export const baseFetchPlayerStreakStats = async (
  endpoint: string,
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
) => {
  const offset = page * DEFAULT_PAGE_SIZE;
  const params: Array<string> = [];

  if (filters.length > 0) {
    params.push(...filters);
  }
  if (sortBy) {
    params.push(`ordering=${sortBy}`);
  }
  if (offset > 0) {
    params.push(`offset=${offset}`);
  }

  const url = `${endpoint}?${params.join('&')}`;
  const response = await fetch(url);
  if (!response.ok) {
    handleErrors(response, signOut);
  }
  return response.json();
};

export const baseFetchPlayerSpanStats = async (
  endpoint: string,
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
) => {
  const offset = page * DEFAULT_PAGE_SIZE;
  const params: Array<string> = [];

  if (filters.length > 0) {
    params.push(...filters);
  }
  if (sortBy) {
    params.push(`ordering=${sortBy}`);
  }
  if (offset > 0) {
    params.push(`offset=${offset}`);
  }

  const url = `${endpoint}?${params.join('&')}`;
  const response = await fetch(url);
  if (!response.ok) {
    handleErrors(response, signOut);
  }
  return response.json();
};

export const baseFetchGolferTournamentStats = async (
  endpoint: string,
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_GOLFER_TOURNAMENT_ORDERING,
  uuid?: string,
) => {
  const offset = page * DEFAULT_PAGE_SIZE;
  const params: Array<string> = [];

  if (filters.length > 0) {
    params.push(...filters);
  }
  if (sortBy) {
    params.push(`ordering=${sortBy}`);
  }
  if (offset > 0) {
    params.push(`offset=${offset}`);
  }

  if (uuid && uuid.length > 0) {
    params.push(`search_uuid=${uuid}`);
  }

  const url = `${endpoint}?${params.join('&')}`;
  const response = await fetch(url);
  if (!response.ok) {
    handleErrors(response, signOut);
  }
  return response.json();
};

export const baseFetchGolferRoundStats = async (
  endpoint: string,
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_GOLFER_ROUND_ORDERING,
  uuid?: string,
) => {
  const offset = page * DEFAULT_PAGE_SIZE;
  const params: Array<string> = [];

  if (filters.length > 0) {
    params.push(...filters);
  }
  if (sortBy) {
    params.push(`ordering=${sortBy}`);
  }
  if (offset > 0) {
    params.push(`offset=${offset}`);
  }

  if (uuid && uuid.length > 0) {
    params.push(`search_uuid=${uuid}`);
  }

  const url = `${endpoint}?${params.join('&')}`;
  const response = await fetch(url);
  if (!response.ok) {
    handleErrors(response, signOut);
  }
  return response.json();
};

export const fetchPlayers = async (filters: string, signOut: () => boolean, apiUrl: string) => {
  const url = `${apiUrl}?${filters}`;
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchTeams = async (filters: string, signOut: () => boolean, apiUrl: string) => {
  const url = `${apiUrl}?${filters}`;
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchGolfTournaments = async (filters: string, signOut: () => boolean, apiUrl: string) => {
  const url = `${apiUrl}?${filters}`;
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchGolfCourses = async (filters: string, signOut: () => boolean, apiUrl: string) => {
  const url = `${apiUrl}?${filters}`;
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchFranchiseHistory = async (filters: string, signOut: () => boolean, apiUrl: string) => {
  const url = `${apiUrl}?${filters}`;
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchFranchiseDetail = async (filters: string, signOut: () => boolean, apiUrl: string) => {
  const url = `${apiUrl}?${filters}`;
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchPlayerDetail = async (signOut: () => boolean, apiUrl: string) => {
  const response = await fetch(apiUrl);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchPlayerStats = async (signOut: () => boolean, apiUrl: string) => {
  const response = await fetch(apiUrl);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchFranchiseSeason = async (filters: string, signOut: () => boolean, apiUrl: string) => {
  const url = `${apiUrl}?${filters}`;
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchVenues = async (filters: string, signOut: () => boolean, apiUrl: string) => {
  const url = `${apiUrl}?${filters}`;
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchSearch = async (id: string) => {
  const url = `${API_URLS.search}/${id}`;
  const response = await fetch(url);
  if (!response.ok) {
    // TODO: handle errors
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchTournamentDetail = async (signOut: () => boolean, apiUrl: string) => {
  const response = await fetch(apiUrl);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchTournamentResults = async (signOut: () => boolean, apiUrl: string) => {
  const response = await fetch(apiUrl);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchCourseDetail = async (signOut: () => boolean, apiUrl: string) => {
  const response = await fetch(apiUrl);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const fetchCourseStats = async (signOut: () => boolean, apiUrl: string) => {
  const response = await fetch(apiUrl);
  if (!response.ok) {
    if (response.status === 401) {
      signOut();
    }
    throw new Error('Network response was not ok');
  }
  return response.json();
};
