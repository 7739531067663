import axios from "axios";
import API_URLS from "../../../api/api_urls";

export const saveSearchAndNavigate = (
  filterContext: any,
  queryString: string,
  successFunction: (id: string) => void
) => {
  axios.post(API_URLS.search, {
    context: {
      filterContext,
    },
    query_string: queryString,
  }).then((response) => {
    const newId = response.data.id;
    successFunction(newId);
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error);
  });
};

export default saveSearchAndNavigate;