import React from 'react';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import BoxScore from './BoxScore';
import GamePlayerStatsTable from './GamePlayerStatsTable';
import RecentGames from './RecentGames';

function GameLayout() {
  return (
    <Grid container direction='row' spacing={3} marginTop={1}>
      <Grid item xs={9}>
        <Grid container direction="column">
          <Grid item>
            <Paper elevation={2}>
              <BoxScore />
            </Paper>
          </Grid>
          <Grid item>
            <GamePlayerStatsTable
              teamName='Duke Blue Devils'
              coachName='Jon Seyer'
              teamLogoUrl='/TeamLogo_FDU.png'
            />
          </Grid>
          <Grid item>
            <GamePlayerStatsTable
              teamName='Kansas Jayhawks'
              coachName='Bill Self'
              teamLogoUrl='/TeamLogo_FDU.png'
            />
          </Grid>
        </Grid>  
      </Grid>
      <Grid item xs={3}>
        <Grid container direction="column">
          <Grid item>
            <Paper elevation={2}>
              <RecentGames />
            </Paper>
          </Grid>  
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GameLayout;
