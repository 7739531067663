import Cookies from 'js-cookie';
import axios from 'axios';
import API_URLS from './api_urls';

export const doLogin = async (
  username: string,
  password: string,
  success: any,
  failure: any
) => {
  const data = {username, password};
  axios.post(API_URLS.auth.login, data, {
    headers: {'X-CSRFToken': Cookies.get('csrftoken')}
  }).then(success).catch(failure);
};

export const doLogout = async () => {
  axios.post(API_URLS.auth.logout);
};

export const doRefresh = async (
  refreshToken: string,
) => axios.post(API_URLS.auth.refreshToken, {refresh: refreshToken}, {
  headers: {'X-CSRFToken': Cookies.get('csrftoken')}
});
