import React from 'react';
import Grid from '@mui/material/Grid';
import { useSignOut } from 'react-auth-kit';
import { Container } from '@mui/material';
import EliasBackButton from '../../../atoms/EliasBackButton/EliasBackButton';
import ROUTER_URLS from '../../../utils/router/urls';
import ResultsLoading from '../../../compounds/ResultsLoading/ResultsLoading';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { useFilterContext, useFilterDispatchContext } from '../../../contexts/FilterContext';
import PlayerSpanFilters from '../../../compounds/PlayerSpanFilters/PlayerSpanFilters';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import PlayerSpanTable from '../../../compounds/PlayerSpanTable/PlayerSpanTable';
import { fetchNcaaPlayerSpanStats } from '../../../../api/ncaambb';
import { appendContextFilters } from '../../../utils/ncaambb/search';
import { statsFilters } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories, gameModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaMbbPlayerFiltersLayout from '../../../compounds/ncaambb/NcaaMbbPlayerFiltersLayout';
import { mapRows } from '../../../utils/ncaambb/span-finder/map-results';
import { mapColumns } from '../../../utils/ncaambb/span-finder/map-columns';

export const DEFAULT_PLAYER_GAME_ORDERING = {
  field: 'game_date',
  sort: 'desc',
};

function PlayerSpanFinderResults() {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();
  const signOut = useSignOut();

  const [columnHeaders, setColumnHeaders] = React.useState<any>();
  const [resultsData, setResultsData] = React.useState<any>();
  const [tableDataLoading, setTableDataLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>();

  // @todo: reminder that this isnt ideal
  // as filters continue to move into the context clean this up
  const fetchPlayerSpanData = (sortBy: string, page: number) => {
    const filters: string[] = [];
    const combinedFilters = appendContextFilters(filters, filterContext, statsFilters, filterContext.spanLength);

    setError(null);
    setTableDataLoading(true);
    fetchNcaaPlayerSpanStats(
      combinedFilters,
      signOut,
      page,
      sortBy,
    ).then((data: any) => {
      const rows = mapRows(data.results, filterContext.groupBy, page);
      const mappedData = {
        ...data,
        results: rows,
      }
      setResultsData(mappedData);
      setTableDataLoading(false);
      setError(null);
    }).catch((responseError) => {
      setTableDataLoading(false);
      setError(responseError)
      filterDispatchContext({
        type: 'changeSearchStatus',
        searchEnabled: true,
      });
    });

  };

  const fetchDataAndMapResults = (sortByParam: string, pageParam: number) => { 
    const columns = mapColumns(filterContext.groupBy, filterContext.selectedStatsFilters);
    setColumnHeaders(columns);
    fetchPlayerSpanData(sortByParam, pageParam);;
  };

  React.useEffect(() => {
    const columns = mapColumns(filterContext.groupBy, filterContext.selectedStatsFilters);
    setColumnHeaders(columns || []);
    fetchPlayerSpanData(filterContext.sortBy, filterContext.page);;
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  const resetFilters = () => {
    filterDispatchContext({
      type: 'resetFilters',
    });
  };

  const handleDone = () => {
    filterDispatchContext({
      type: 'pageByChanged',
      page: 0,
    });
    filterDispatchContext({
      type: 'sortByChanged',
      sortBy: '-game_date',
    });
    filterDispatchContext({
      type: 'changeSearchStatus',
      searchEnabled: false,
    });
    const columns = mapColumns(filterContext.groupBy, filterContext.selectedStatsFilters);
    setColumnHeaders(columns);
    fetchDataAndMapResults('-game_date', 0);
  };

  const pageSpecificSearchEnabled = (context: ContextFilterType): boolean =>
    Boolean(context.spanLength) && context.spanLength > 1;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const topModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
    />
  );

  return (
    <Dashboard>
      <Container sx={{ py: 2 }} maxWidth="xl">
        <Grid
          container
          direction="column"
          spacing={4}
          className="player-game-finder-container"
        >
          <Grid item>
            <EliasBackButton route={ROUTER_URLS.ncaambb.playerSpanFinderSearch} />
          </Grid>
          <Grid item>
            <NcaaMbbPlayerFiltersLayout
              resetFilters={resetFilters}
              handleDone={handleDone}
              statsFilterPartial={statsFilterPartial}
              pageSpecificSearchEnabled={pageSpecificSearchEnabled}
              apiUrls={API_URLS.ncaambb}
              topModalPartial={topModalPartial}
              bottomModalPartial={bottomModalPartial}
            >
              <PlayerSpanFilters />
            </NcaaMbbPlayerFiltersLayout>
          </Grid>
        </Grid>
        <Grid item sx={{ py: 3 }}>
          { tableDataLoading || error ?
            <ResultsLoading error={error} /> :
            <PlayerSpanTable
              data={resultsData?.results ?? []}
              dataCount={resultsData?.count ?? 0}
              dataMetaInfo={resultsData?.query?.back_to ?? []}
              columnData={columnHeaders || []}
              handleDone={fetchDataAndMapResults}
              appUrls={ROUTER_URLS.ncaambb}
            />
          }
        </Grid>
      </Container>
    </Dashboard>
  );
}

export default PlayerSpanFinderResults;
