import React from 'react';
import { Stack, Typography } from '@mui/material';

function BoxScoreHeader() {
  return (
    <Stack
      direction='row'
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography
        component="h4"
        variant="h6"
        fontWeight="600"
        padding={2}
      >
        Nov 15, 2022 9:30 PM ET
      </Typography>
      <Typography
        component="div"
        variant='body1'
        padding={2}
      >
        Gainbridge Fieldhouse Indianapolis, IN
      </Typography>
    </Stack>
  );
}

export default BoxScoreHeader;
