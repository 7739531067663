import theme from '../../utils/theme/theme';

const ButtonStyles = {
  fontWeight: 'bold',
  fontSize: '14px',
  color: theme.palette.custom.mediumDarkGrey,
  textTransform: 'capitalize',
  borderColor: theme.palette.custom.mediumDarkGrey,
};

export default ButtonStyles;
