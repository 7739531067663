import { ModalFilterCategoriesType } from "../../types/modal";

const CURRENT_YEAR = '2023';

export const advancedModalFilterCategories: ModalFilterCategoriesType[] = [
  {
    category: 'leaderboard_situation',
    categoryLabel: 'Leaderboard Situation',
    filters: [
      {
        id: 'wire-to-wire-winner',
        label: 'Wire-to-Wire Winner',
        subLabel: '',
        searchTerms: ['Wire-to-Wire Winner'],
        filterType: 'checkbox',
        chip: 'Wire-to-Wire Winner'
      },
      {
        id: 'outright-leader-after-round-1',
        label: 'Outright Leader After Round 1',
        subLabel: '',
        searchTerms: ['Leader After Round 1'],
        filterType: 'checkbox',
        chip: 'Outright Leader After RD1'
      },
      {
        id: 'tied-for-lead-after-round-1',
        label: 'Tied for the Lead After Round 1',
        subLabel: '',
        searchTerms: ['Tied for the Lead After Round 1'],
        filterType: 'checkbox',
        chip: 'Tied for the Lead After RD1'
      },
      {
        id: 'outright-leader-after-round-2',
        label: 'Outright Leader After Round 2',
        subLabel: '',
        searchTerms: ['Outright Leader After Round 2'],
        filterType: 'checkbox',
        chip: 'Outright Leader After RD2'
      },
      {
        id: 'tied-for-lead-after-round-2',
        label: 'Tied for the Lead After Round 2',
        subLabel: '',
        searchTerms: ['Tied for the Lead After Round 2'],
        filterType: 'checkbox',
        chip: 'Tied for the Lead After RD2'
      },
      {
        id: 'outright-leader-after-round-3',
        label: 'Outright Leader After Round 3',
        subLabel: '',
        searchTerms: ['Outright Leader After Round 3'],
        filterType: 'checkbox',
        chip: 'Outright Leader After RD3'
      },
      {
        id: 'tied-for-lead-after-round-3',
        label: 'Tied for the Lead After Round 3',
        subLabel: '',
        searchTerms: ['Tied for the Lead After Round 3'],
        filterType: 'checkbox',
        chip: 'Tied for the Lead After RD3'
      },
    ]
  },
];
/* 
Leader entering round
Leader by 1,2,X entering round
Tied for Lead entering round
Made the Cut on the Number
Trail by 1,2,X entering round
Top 5,10, X entering round
Outside top 5,10, X entering round
*/

export const roundModalFilterCategories: ModalFilterCategoriesType[] = [
  {
    category: 'tournament_types',
    categoryLabel: 'Tournament Types',
    filters: [
      {
        id: 'major',
        label: 'Major Tournaments',
        subLabel: 'Stats available since XX-XX',
        searchTerms: ['Major Tournaments'],
        filterType: 'checkbox',
        chip: 'Major Tournaments'
      },
      {
        id: 'playoffs',
        label: 'Fedex Cup Playoffs',
        subLabel: 'Stats available since XX-XX',
        searchTerms: ['FedEx Cup Playoffs'],
        filterType: 'checkbox',
        chip: 'FedEx Cup Playoffs'
      },
    ]
  },
  {
    category: 'round_filters',
    categoryLabel: 'Round Filters',
    filters: [
      {
        id: 'round-1',
        label: 'Round 1',
        subLabel: '',
        searchTerms: ['Round 1'],
        filterType: 'checkbox',
        chip: 'Round 1'
      },
      {
        id: 'round-2',
        label: 'Round 2',
        subLabel: '',
        searchTerms: ['Round 2'],
        filterType: 'checkbox',
        chip: 'Round 2'
      },
      {
        id: 'round-3',
        label: 'Round 3',
        subLabel: '',
        searchTerms: ['Round 3'],
        filterType: 'checkbox',
        chip: 'Round 3'
      },
      {
        id: 'round-4',
        label: 'Round 4',
        subLabel: '',
        searchTerms: ['Round 4'],
        filterType: 'checkbox',
        chip: 'Round 4'
      },
      {
        id: 'final-round',
        label: 'Final Round (Any)',
        subLabel: 'Includes all final rounds, regardless of round number',
        searchTerms: ['Final Round'],
        filterType: 'checkbox',
        chip: 'Final Round'
      },
      {
        id: 'course-record',
        label: 'Course Record',
        subLabel: '',
        searchTerms: ['Course Record'],
        filterType: 'checkbox',
        chip: 'Course Record'
      },
      {
        id: 'tournament-record',
        label: 'Tournament Record',
        subLabel: '',
        searchTerms: ['Tournament Record'],
        filterType: 'checkbox',
        chip: 'Tournament Record'
      },
      {
        id: 'ringer-round',
        label: 'Ringer Round',
        subLabel: 'A ringer round is when one player outright has the best round among all players on a single day',
        searchTerms: ['Ringer Round'],
        filterType: 'checkbox',
        chip: 'Ringer Round'
      },
    ]
  },
  {
    category: 'date',
    categoryLabel: 'Date Filters',
    filters: [
      {
        id: 'season_group',
        label: 'Current Season',
        subLabel: '',
        searchTerms: [''],
        filterType: 'radio',
        chip: 'year',
        radioGroup: [
          {
            id: 'current_year',
            label: 'Current Year',
            subLabel: '',
            value: CURRENT_YEAR,
            type: 'default',
            chip: 'Current Year',
          },
          {
            id: 'date_range',
            label: 'Date Range',
            subLabel: '',
            value: '',
            type: 'custom_date_range',
            chip: '[INPUT_VALUE] - [INPUT_END_VALUE]',
          },
        ]
      },
    ]
  },
]

export const tournamentModalFilterCategories: ModalFilterCategoriesType[] = [
  {
    category: 'tournament_types',
    categoryLabel: 'Tournament Types',
    filters: [
      {
        id: 'major',
        label: 'Major Tournaments',
        subLabel: 'Stats available since XX-XX',
        searchTerms: ['Major Tournaments'],
        filterType: 'checkbox',
        chip: 'Major Tournaments'
      },
      {
        id: 'playoffs',
        label: 'Fedex Cup Playoffs',
        subLabel: 'Stats available since XX-XX',
        searchTerms: ['FedEx Cup Playoffs'],
        filterType: 'checkbox',
        chip: 'FedEx Cup Playoffs'
      },
    ]
  },
  {
    category: 'date',
    categoryLabel: 'Date Filters',
    filters: [
      {
        id: 'season_group',
        label: 'Current Season',
        subLabel: '',
        searchTerms: [''],
        filterType: 'radio',
        chip: 'year',
        radioGroup: [
          {
            id: 'current_year',
            label: 'Current Year',
            subLabel: '',
            value: CURRENT_YEAR,
            type: 'default',
            chip: 'Current Year',
          },
          {
            id: 'date_range',
            label: 'Date Range',
            subLabel: '',
            value: '',
            type: 'custom_date_range',
            chip: '[INPUT_VALUE] - [INPUT_END_VALUE]',
          },
        ]
      },
    ]
  },
]
