import React from 'react';
import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const StyledTableCell = styled(TableCell)({
  padding: 4,
});

const StyledTableHeaderCell = styled(TableCell)({
  padding: 4,
  fontWeight: 650,
});

const StyledTotalCell = styled(TableCell)({
  padding: 4,
  fontWeight: 650,
});

function BoxScoreSplitStats() {
  return (
    <Box
      paddingX={10}
      display='flex'
      height='100%'
      justifyContent='center'
      justifyItems='center'
      alignItems='center'
      alignContent='center'
    >
      <Table aria-label="simple table" padding='none'>
        <TableHead>
          <TableRow>
            <StyledTableHeaderCell />
            <StyledTableHeaderCell align="center">1</StyledTableHeaderCell>
            <StyledTableHeaderCell align="center">2</StyledTableHeaderCell>
            <StyledTableHeaderCell align="center">OT</StyledTableHeaderCell>
            <StyledTableHeaderCell align="center">T</StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={1}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <StyledTableCell component="th" scope="row">
              Duke
            </StyledTableCell>
            <StyledTableCell align="center">24</StyledTableCell>
            <StyledTableCell align="center">30</StyledTableCell>
            <StyledTableCell align="center">10</StyledTableCell>
            <StyledTotalCell align="center">64</StyledTotalCell>
          </TableRow>
          <TableRow
            key={1}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <StyledTableCell component="th" scope="row">
              Kansas
            </StyledTableCell>
            <StyledTableCell align="center">24</StyledTableCell>
            <StyledTableCell align="center">30</StyledTableCell>
            <StyledTableCell align="center">15</StyledTableCell>
            <StyledTotalCell align="center">69</StyledTotalCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default BoxScoreSplitStats;
