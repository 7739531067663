import React from 'react';
import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';

interface IProps {
  uniqueRowKey: string;
  headers: string[];
  data: any[];
  dataMap: any;
  labelHeaderRow?: React.ReactElement;
  headerCellStyles?: any;
  tableCellStyles?: any;
  linkableItems: any[];
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.custom.lighterGrey,
    textAlign: 'left',
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    textAlign: 'left',
  },
}));

function linkify(rowAttribute, rowValue, linkableItems: []) {
  console.log(rowAttribute);
  const linkifiedValue = linkableItems.indexOf(rowAttribute) >-1 ? <a href="https://www.pgachampionship.com">{rowValue}</a> : rowValue;
  return linkifiedValue;
}

function StyledTable({ uniqueRowKey, headers, data, dataMap, labelHeaderRow, headerCellStyles, tableCellStyles, linkableItems}: IProps) {
  const headersPartial = (
    <TableHead>
      { labelHeaderRow }
      <TableRow>
        <StyledTableCell key={headers[0]} sx={headerCellStyles}>{headers[0]}</StyledTableCell>
        {
          headers.slice(1).map((header: string) => <StyledTableCell key={header} align="right" sx={headerCellStyles}>{header}</StyledTableCell>)
        }
      </TableRow>
    </TableHead>
  );

  const bodyPartial = (
    <TableBody>
      {data.map((row, index) => (
        <TableRow
          key={row[uniqueRowKey]}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {
            headers.map((header: string) => {
              const key = `${row[uniqueRowKey]}-${header}`;
              const attribute = dataMap[header];
              const value = row[attribute];
              return (
                <StyledTableCell key={key} align="right" sx={tableCellStyles}>
                  {attribute.toLowerCase() === 'row' ? index + 1 : linkify(attribute, value, linkableItems) }
                </StyledTableCell>
              );
            })
          }
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        { headersPartial }
        { bodyPartial }
      </Table>
    </TableContainer>
  );
};

export default StyledTable;
