import { ColumnMappingType } from "../../../types/results";
import { SelectedStatFilterType } from "../../../types/statsFilter";
import { showHideColumns } from "../../common/results";

const DEFAULT_COL_WIDTH = 80;
// const TINY_COL_WIDTH = 10;
// const MEDIUM_COL_WIDTH = 145;
const WIDE_COL_WIDTH = 185; // fits longest player display name at 24 characters

export const ungroupedColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: 40,
    sortable: false,
    display: 'alwaysFirst',
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'Player',
    field: 'player__display_name',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Streak',
    field: 'streak',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'First',
    field: 'first',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Last',
    field: 'last',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  // {
  //   headerName: 'GS',
  //   field: 'sum__started',
  //   width: DEFAULT_COL_WIDTH,
  //   display: 'alwaysThird',
  // },
  {
    headerName: 'PTS/G',
    field: 'per_game__points',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'REB/G',
    field: 'per_game__total_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'AST/G',
    field: 'per_game__assists',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'BLK/G',
    field: 'per_game__blocks',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'STL/G',
    field: 'per_game__steals',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'FG%',
    field: 'sum__field_goal_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '3FG%',
    field: 'sum__three_point_field_goal_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'FT%',
    field: 'sum__free_throw_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'TRBDS',
    field: 'sum__triple_double',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'Games',
    field: 'games',
    width: WIDE_COL_WIDTH * 2,
    display: 'alwaysThird',
  },
  {
    headerName: 'FG/G',
    field: 'per_game__field_goals_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FGA/G',
    field: 'per_game__field_goal_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FG3M/G',
    field: 'per_game__three_point_field_goals_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FG3A/G',
    field: 'per_game__three_point_field_goal_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FT/G',
    field: 'per_game__free_throws_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FTA/G',
    field: 'per_game__free_throw_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'OREB/G',
    field: 'per_game__offensive_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'DREB/G',
    field: 'per_game__defensive_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'PPTS/G',
    field: 'per_game__points_in_paint',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'PTO/G',
    field: 'per_game__points_off_turnovers',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'DKS/G',
    field: 'per_game__dunks',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'PTS2/G',
    field: 'per_game__second_chance_points',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'DBLDBL/G',
    field: 'per_game__double_double',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'TDBL/G',
    field: 'per_game__triple_double',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'TO/G',
    field: 'per_game__turnovers',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: '+/-/G',
    field: 'per_game__plus_minus',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
];

export const mapColumns = (groupByColumn: string, localFilters: SelectedStatFilterType[]) =>
  showHideColumns(ungroupedColumnMapping, localFilters);
