import { ColumnMappingType } from "../../../types/results";
import { SelectedStatFilterType } from "../../../types/statsFilter";
import { showHideColumns } from "../../common/results";

const DEFAULT_COL_WIDTH = 80;
const TINY_COL_WIDTH = 10;
const MEDIUM_COL_WIDTH = 145;
const WIDE_COL_WIDTH = 185; // fits longest player display name at 24 characters

export const ungroupedColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: 40,
    sortable: false,
    display: 'alwaysFirst',
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'Player',
    field: 'player__display_name',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Game Date',
    field: 'game__date',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Game Type',
    field: 'game__game_class',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Team',
    field: 'franchise_season__abbr_3',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: ' ',
    field: 'loc',
    width: TINY_COL_WIDTH,
    sortable: false,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Opp',
    field: 'opponent_season__abbr_3',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Result',
    field: 'result',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },

  {
    headerName: 'GS',
    field: 'started',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'MIN',
    field: 'minutes_display',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'PTS',
    field: 'points',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'FG',
    field: 'field_goals_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FGA',
    field: 'field_goal_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FG%',
    field: 'field_goal_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FG3M',
    field: 'three_point_field_goals_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FG3A',
    field: 'three_point_field_goal_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: '3FG%',
    field: 'three_point_field_goal_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FT',
    field: 'free_throws_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FTA',
    field: 'free_throw_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'FT%',
    field: 'free_throw_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'OREB',
    field: 'offensive_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'DREB',
    field: 'defensive_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'PPTS',
    field: 'points_in_paint',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'PTO',
    field: 'points_off_turnovers',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'DKS',
    field: 'dunks',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'PTS2',
    field: 'second_chance_points',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'TDBL',
    field: 'triple_double',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'DQ',
    field: 'disqualified',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  {
    headerName: 'REB',
    field: 'total_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'AST',
    field: 'assists',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'STL',
    field: 'steals',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'BLK',
    field: 'blocks',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'TO',
    field: 'turnovers',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'PF',
    field: 'personal_fouls',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: '(+/-)',
    field: 'plus_minus',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'TM Score',
    field: 'franchise_score',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'OPP Score',
    field: 'opponent_score',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
  {
    headerName: 'SC Diff',
    field: 'score_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
  },
];

export const playerColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerFranchiseColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Franchise',
    field: 'franchise_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerOpponentColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Opponent',
    field: 'opponent_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerFranchiseSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Franchise',
    field: 'franchise_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerOpponentSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Opponent',
    field: 'opponent_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const mapColumns = (groupByColumn: string, localFilters: SelectedStatFilterType[]) => {
  switch (groupByColumn) {
    case 'player':
      return playerColumnMapping;
    case 'player-season':
      return playerSeasonColumnMapping;
    case 'player-franchise':
      return playerFranchiseColumnMapping;
    case 'player-opponent':
      return playerOpponentColumnMapping;
    case 'player-franchise-season':
      return playerFranchiseSeasonColumnMapping;
    case 'player-opponent-season':
      return playerOpponentSeasonColumnMapping;
    default:  
      return showHideColumns(ungroupedColumnMapping, localFilters);
  };
};