import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import Box from '@mui/system/Box';
import DataGridStyles from './StyleOverrides';
import './EliasDataGrid.scss';
import { generateCellClass, renderCell } from './data-grid-helpers';
import { DEFAULT_PAGE_SIZE } from '../../../api/api';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { AppLeagueRoutesType } from '../../utils/router/urls';

interface EliasDataGridProps {
  columns: GridColDef[];
  rows: GridRowModel[];
  rowCount: number;
  handleDone: (sortByParam: string, pageParam: number) => void;
  appUrls?: AppLeagueRoutesType;
}

const DEFAULT_ROW_HEIGHT = 44;

function EliasDataGrid({
  columns,
  rows,
  rowCount,
  handleDone,
  appUrls,
}: EliasDataGridProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();
  const sortBy: string = filterContext.sortBy || '-game_date';

  const initialSortByDirection = sortBy[0] === "-" ? "desc" : "asc";
  const initialSortByField = sortBy[0] === "-" ? sortBy.substring(1) : sortBy;

  const [sortByDirection, setSortByDirection] = React.useState<GridSortDirection>(initialSortByDirection);
  const [sortByField, setSortByField] = React.useState<string>(initialSortByField);

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { sort: sortByDirection, field: sortByField }
  ]);

  const [rowCountState, setRowCountState] = React.useState(
    rowCount
  );

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const formattedGridColumns = columns.map((col: any) => ({
    ...col,
    cellClassName: generateCellClass(sortByField),
    headerClassName: generateCellClass(sortByField),
    width: col.width,
    renderCell: renderCell(col.field, appUrls),
  }));

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const firstSortModel = newSortModel[0];
    if (firstSortModel) {
      setSortModel(newSortModel);
      const direction = firstSortModel.sort === 'desc' ? '-' : '';
      const ordering = `${direction}${firstSortModel.field}`;
      filterDispatchContext({
        type: 'sortByChanged',
        sortBy: ordering,
      });
      setSortByDirection(firstSortModel.sort);
      setSortByField(firstSortModel.field)
      handleDone(ordering, 0);
    }
  };

  const handlePageChange = (page: number) => {
    filterDispatchContext({
      type: 'pageByChanged',
      page,
    });
    handleDone(filterContext.sortBy, page);
  };

  return (
    <Box>
      <DataGrid
        columns={formattedGridColumns}
        rows={rows}
        rowCount={rowCountState}
        rowHeight={DEFAULT_ROW_HEIGHT}
        rowsPerPageOptions={[25]}
        getRowId={(row) => row.rank ?? row.id}
        disableColumnFilter
        disableColumnMenu
        headerHeight={DEFAULT_ROW_HEIGHT}
        page={filterContext.page}
        pageSize={DEFAULT_PAGE_SIZE}
        onPageChange={handlePageChange}
        paginationMode="server"
        sortingOrder={['desc', 'asc']}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(model) => handleSortModelChange(model)}
        sx={DataGridStyles}
        autoHeight
      />
    </Box>
  );
}

export default EliasDataGrid;
