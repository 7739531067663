import { Container } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import API_URLS from '../../../../api/api_urls';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import TeamLayout from '../../../compounds/TeamLayout/TeamLayout';
import { useFetchFranchiseDetail, useFetchFranchiseHistory } from '../../../hooks/useFetch';

function NcaaMbbTeam() {
  const { teamId } = useParams();
  const apiUrl = API_URLS.ncaambb;

  const { data: franchiseDetailData, isLoading: detailLoading, error: detailError } = useFetchFranchiseDetail(teamId || '', apiUrl.franchise_profile);
  const { data: franchiseHistoryData, isLoading: historyLoading, error: historyError } = useFetchFranchiseHistory(teamId || '', apiUrl.franchise_history);

  if (detailLoading || historyLoading) {
    return <span>Loading...</span>
  }

  if (detailError && detailError instanceof Error) {
    return <span>Error: {detailError.message}</span>
  }

  if (historyError && historyError instanceof Error) {
    return <span>Error: {historyError.message}</span>
  }

  return (
    <Dashboard>
      <Container sx={{ py: 2 }} maxWidth="xl">
        <TeamLayout
          teamId={teamId || ''}
          franchiseDetailData={franchiseDetailData?.results || []}
          franchiseHistoryData={franchiseHistoryData?.results || []}
        />
      </Container>
    </Dashboard>
  );
}

export default NcaaMbbTeam;
