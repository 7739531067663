import React from 'react';
import { Chip, Grid, Stack, Typography } from '@mui/material';

function RecentGameChip({ chipValue, win }: { chipValue: string, win: boolean }) {
  const chipColor = win ? '#ECFDF3' : '' ;
  const fontColor = win ? '#027A48' : '';
  return (
    <Chip label={chipValue} sx={{ backgroundColor: `${chipColor}`, color: `${fontColor}`, fontWeight: 600 }} />
  );
};

function RecentGameResult({ date, homeAbbr, homeScore, awayAbbr, awayScore, explainerText }: { date: string, homeAbbr: string, homeScore: number, awayAbbr: string, awayScore: number, explainerText?: string }) {
  const homeWin = homeScore > awayScore;
  
  return (
    <Grid item>
      <Typography
        component="div"
        variant='body1'
        paddingX={2}
        paddingY={1}
      >
        { date }
      </Typography>
      <Stack direction='row' sx={{ alignItems: 'center', paddingX: 2, paddingY: 1 }}>
        <RecentGameChip win={!homeWin} chipValue={`${awayAbbr} ${awayScore}`} />
        <Typography
          component="div"
          variant='body1'
          paddingX={1}
        >
          @
        </Typography>
        <RecentGameChip win={homeWin} chipValue={`${homeAbbr} ${homeScore}`} />
        { explainerText && <Typography
          component="div"
          variant='body1'
          fontWeight={600}
          paddingX={1}
        >
          { explainerText }
        </Typography>}
      </Stack>
    </Grid>
  );
};

function RecentGames() {
  return (
    <Grid container direction='column'>
      <Grid item>
        <Stack
          direction='row'
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            component="h4"
            variant="h6"
            fontWeight="600"
            padding={2}
            fontSize='1em'
          >
            Last 5 Games
          </Typography>
        </Stack>
      </Grid>
      <RecentGameResult date='Nov 03, 2022' homeAbbr='DUKE' homeScore={68} awayAbbr='KU' awayScore={78} />
      <RecentGameResult date='Mar 25, 2018' homeAbbr='KU' homeScore={72} awayAbbr='DUKE' awayScore={63} />
      <RecentGameResult date='Nov 15, 2016' homeAbbr='DUKE' homeScore={82} awayAbbr='KU' awayScore={57} />
      <RecentGameResult date='Nov 12, 2013' homeAbbr='KU' homeScore={58} awayAbbr='DUKE' awayScore={59} />
      <RecentGameResult date='Nov 23, 2011' homeAbbr='KU' homeScore={82} awayAbbr='DUKE' awayScore={73} explainerText='2 OT' />
    </Grid>
  );
}

export default RecentGames;
