import React from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import API_URLS from '../../../../api/api_urls';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import GolfTournamentHistoryLayout from '../../../compounds/GolfTournamentHistoryLayout/GolfTournamentHistoryLayout';
import { useFetchTournamentDetail, useFetchTournamentResults } from '../../../hooks/useFetch';

function GolfTournamentHistory() {
  const { tournamentId } = useParams();

  const apiUrl = API_URLS.golf;

  const { data: tournamentHistoryDetailData, isLoading: isProfileLoading, error: profileError } = useFetchTournamentDetail(tournamentId || '', apiUrl.tournament_history_detail);
  const { data: tournamentHistoryResultsData, isLoading: isStatsLoading, error: statsError } = useFetchTournamentResults(tournamentId || '', apiUrl.tournament_history_results);

  if (isProfileLoading || isStatsLoading) {
    return <span>Loading...</span>
  }

  if (profileError && profileError instanceof Error) {
    return <span>Error: {profileError.message}</span>
  }

  if (statsError && statsError instanceof Error) {
    return <span>Error: {statsError.message}</span>
  }

  if (!tournamentHistoryDetailData || !tournamentHistoryResultsData) {
    return <span>Error: Api failed to respond</span>
  }

  return (
    <Dashboard>
      <Container sx={{ py: 2 }} maxWidth="xl">
        <GolfTournamentHistoryLayout tournamentHistoryDetails={tournamentHistoryDetailData} tournamentHistoryResultsData={tournamentHistoryResultsData} />
      </Container>
    </Dashboard>
  );
}

export default GolfTournamentHistory;
