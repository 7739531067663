import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import './Header.scss';

interface HeaderProps {
  title: string;
  subtitle?: string;
}

function Header({ title, subtitle }: HeaderProps) {
  return (
    <Grid className="header-container">
      <Box className="header-title">{title}</Box>
      <Box className="header-subtitle">{subtitle}</Box>
    </Grid>
  );
}

export default Header;
