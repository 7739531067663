import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import { Paper } from '@mui/material';
import './PlayerFiltersLayout.scss';
import SearchButtonStyles from './StyleOverrides';
import { useFilterContext } from '../../contexts/FilterContext';
import EliasNameFilter from '../EliasNameFilter/EliasNameFilter';
import GolfTournamentFilter from '../GolfTournamentFilter/GolfTournamentFilter';
import GolfCourseFilter from '../GolfCourseFilter/GolfCourseFilter';
import { searchEnabled } from '../../utils/golf/search';
import { IApiUrlFinders } from '../../../api/api_urls';
import { ContextFilterType } from '../../types/context';
import GolfFastFilters from '../FastFilters/GolfFastFilters';


interface IProps {
  resetFilters: () => void;
  handleDone: () => void;
  pageSpecificSearchEnabled: (context: ContextFilterType) => boolean,

  statsFilterPartial: React.ReactNode;
  topModalPartial?: React.ReactNode;
  bottomModalPartial?: React.ReactNode;

  children?: React.ReactElement;

  apiUrls: IApiUrlFinders,
}

function GolfTournamentFiltersLayout({
  resetFilters,
  handleDone,
  pageSpecificSearchEnabled,
  statsFilterPartial,
  children,
  apiUrls,
  topModalPartial,
  bottomModalPartial,
}: IProps) {
  const filterContext: any = useFilterContext();

  return (
    <Paper elevation={3}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        paddingX={5}
        paddingY={2}
      >
        <Grid item xs={4}>
          <EliasNameFilter apiUrl={apiUrls.players} />
        </Grid>
        <Grid item xs={4}>
          <GolfTournamentFilter
            label="Search by Tournament"
            actionType='changeTournamentObject'
            actionObject='tournamentObject'
            apiUrl={apiUrls.tournaments}
          />
        </Grid>
        <Grid item xs={4}>
          <GolfCourseFilter
            label="Search by Course"
            actionType='changeCourseObject'
            actionObject='courseObject'
            apiUrl={apiUrls.courses}
          />
        </Grid>
        <Grid item xs={12}>
          { statsFilterPartial }
        </Grid>
        <GolfFastFilters
          showMajorChampion
          showDefendingChampion
          showFirstAppearance
          showProfessionalStatus
          showHandedness
          showNationality
          showRookie
          showCut
          showPlayoff
          showFinish
          showFairwayGrass
          showGreensGrass
        />
        { topModalPartial }
        <Grid item xs={4} />
        { children }
        { bottomModalPartial }
        <Grid item xs={12} paddingTop='0px'>
          <Box className="horizontal-divider" />
        </Grid>
        <Grid container item xs={12} className="filter-buttons">
          <Grid item xs={10}/>
          <Grid item xs={1}>
            <Button variant="outlined" onClick={resetFilters}>
              Reset
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={SearchButtonStyles}
              disabled={!searchEnabled(filterContext, pageSpecificSearchEnabled)}
              variant="contained"
              onClick={handleDone}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default GolfTournamentFiltersLayout;
