import React from 'react';
import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';

interface IProps {
  uniqueRowKey: string;
  headers: string[];
  data: any[];
  dataMap: any;
  labelHeaderRow?: React.ReactElement;
  headerCellStyles?: any;
  tableCellStyles?: any;
};

const GolfScorecardCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.custom.lighterGrey,
    textAlign: 'center',
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    textAlign: 'center',
  },
}));

function GolfScorecard({ data, labelHeaderRow, headerCellStyles, tableCellStyles }: IProps) {
  console.log(data);
  const headersPartial = (
    <TableHead>
      { labelHeaderRow }
      <TableRow>
            <GolfScorecardCell key="hole-number" align="right" sx={headerCellStyles}>
              Hole #
            </GolfScorecardCell>
        {
          data.map((hole: string) => 
            <GolfScorecardCell key={hole.holeNumber} align="right" sx={headerCellStyles}>
              {hole.holeNumber}
            </GolfScorecardCell>)
        }
      </TableRow>
    </TableHead>
  );

  const bodyPartial = (
    <TableBody>
      <TableRow>            
            <GolfScorecardCell key="yardage" align="right" sx={headerCellStyles}>
              Yardage
            </GolfScorecardCell>
        {
          data.map((hole: string) => 
            <GolfScorecardCell key={`${hole.holeNumber}-yardage`} align="right" sx={tableCellStyles}>
              {hole.yardage}
            </GolfScorecardCell>)
        }
      </TableRow>

      <TableRow>
            <GolfScorecardCell key="par" align="right" sx={headerCellStyles}>
              Par
            </GolfScorecardCell>
        {
          data.map((hole: string) => 
            <GolfScorecardCell key={`${hole.holeNumber}-par`} align="right" sx={tableCellStyles}>
              {hole.par}
            </GolfScorecardCell>)
        }
      </TableRow>
    </TableBody>
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        { headersPartial }
        { bodyPartial }
      </Table>
    </TableContainer>
  );
};

export default GolfScorecard;
