import React from 'react';
import { Grid, Typography } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface IProps {
  teamName: string;
  teamRecord: string;
  teamLogoUrl: string;
  teamScore: number;
  teamWin: boolean;
}

function BoxScoreTeamRow({ teamName, teamRecord, teamLogoUrl, teamScore, teamWin }: IProps) {
  return (
    <Grid container direction='row'>
      <Grid item xs={10}>
        <Grid container direction='row'>
          <Grid
            item
            alignItems='center'
            alignContent='center'
            justifyContent='center'
            justifyItems='center'
            display='flex'
            xs={3}
          >
            <img src={teamLogoUrl} height="75" width="75" alt="team-logo" />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={0} direction='column' height='100%' justifyItems='center' justifyContent='center'>
              <Grid item>
              <Typography
                component="h4"
                variant="h6"
                fontWeight="600"
                display='flex'
                width='100%'
              >
                
                { teamName }
              </Typography>
              </Grid>
              <Grid item>
              <Typography
                component="div"
                variant='body1'
                display='flex'
                width='100%'
              >
                { teamRecord }
              </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Typography
          component="h4"
          variant="h6"
          fontWeight="600"
          alignItems='center'
          justifyContent='end'
          display='flex'
          height='100%'
        >
          { teamWin && <ArrowRightIcon /> }
          { teamScore }
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BoxScoreTeamRow;