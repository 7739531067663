import { ResultDetail } from "../../../api/types";
import { ColumnMappingType } from "../../types/results";
import { SelectedStatFilterType } from "../../types/statsFilter";

export const formatResultDetail = (data: ResultDetail[]): string => {
  if (data && data.length > 0) {
    const resultDetail = data.reduce(
      (prev: ResultDetail, current: ResultDetail) => {
        const currentFirstSeason = current.first_season || 0;
        const prevFirstSeason = prev.first_season || 0;
        if (currentFirstSeason > prevFirstSeason) return current;
        return prev;
      }
    );

    const firstSeason = resultDetail?.first_season;
    if (!firstSeason || firstSeason < 1900) return '';
    let detail = `This search shows results since ${resultDetail.first_season}`;
    if (resultDetail.ae_filter_context && resultDetail.ae_filter_context.length > 0)
      detail = `${detail}, ${resultDetail.ae_filter_context}`;
    return detail;
  }
  return '';
};

export const createCsvString = (
  headers: string[],
  fields: string[],
  rows: Object[],
  rowFunction?: (values: string[]) => string[]
): string => {
  let resultsArray: string[] = [];
  const headerString = headers.join('\t');
  if (headerString.length === 0) return '';

  const rowString = rows.map((row) => {
    const values = fields.map((field) => String((row as any)[field]));
    if (rowFunction) return rowFunction(values).join('\t');
    return values.join('\t');
  });
  resultsArray = resultsArray.concat(headerString);
  resultsArray = resultsArray.concat(rowString);
  return resultsArray.join('\n');
};

// we want to show a few info columns first
// then show any stat thats selected
// then show any of our stats we want to always display
export const showHideColumns = (columns: ColumnMappingType[], localFilters: SelectedStatFilterType[]) => {
  let resultColumns = columns.filter(column => column.display === 'alwaysFirst');
  const localFilterIds = localFilters.map(filter => filter.id);
  if (localFilters.length > 0) {
    const secondColumns = columns.filter(column => {
      if (column.display === 'alwaysFirst') return false;
      if (column.field === 'minutes_display') return localFilterIds.includes('seconds');
      if (column.field.startsWith('per_game__')) {
        return localFilterIds.includes(column.field.slice(10));
      };
      if (column.field.startsWith('sum__')) {
        return localFilterIds.includes(column.field.slice(5));
      };
      return localFilterIds.includes(column.field)
    });
    resultColumns = resultColumns.concat(secondColumns);
  }
  const thirdColumns = columns.filter(column => column.display === 'alwaysThird' && !localFilterIds.includes(column.field));
  return resultColumns.concat(thirdColumns);
};
