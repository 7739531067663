import React from 'react';
import { FormControl, FormHelperText, Grid, InputAdornment, OutlinedInput } from '@mui/material';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import './PlayerSpanFilters.scss';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import FinderSelect from '../../atoms/FinderSelect/FinderSelect';
import { DEFAULT_SPAN_RANGE_OPTION, spanRangeOptions } from '../../pages/Nba/PlayerSpanFinder/constants';

function PlayerSpanFilters() {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    filterDispatchContext({
      type: 'changeSpanLength',
      spanLength: newValue,
      searchEnabled: true,
    });
  };

  const handleSpanOptionChange = (selection: string) => {
    filterDispatchContext({
      type: 'spanOptionSelected',
      spanRangeOption: selection,
      searchEnabled: true,
    });
  };

  return (
    <>
      <Grid item xs={4}>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <OfflineBoltIcon className="lightning-icon" />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                * Required
              </InputAdornment>
            }
            placeholder='Span Length'
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            onChange={handleChange}
            value={filterContext?.spanLength}
            required
            error={!!filterContext?.spanLength && Number(filterContext?.spanLength) < 2}
          />
          <FormHelperText>
          { filterContext?.spanLength && filterContext?.spanLength.length > 0 && filterContext?.spanLength < 2 && filterContext?.spanLength >= 0 ?
              'Span length must be at least 2 games' :
              'Enter Number of Games in the Span'
          }
        </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FinderSelect
          label='Span Options'
          value={filterContext.spanRangeOption || DEFAULT_SPAN_RANGE_OPTION}
          options= {spanRangeOptions}
          handleSelection={handleSpanOptionChange}
          selectStyleOverrides={{
            select: { 'minHeight': '56px' },
            formControl: { width: '100%' }
          }}
        />
      </Grid>
    </>
  );
}

export default PlayerSpanFilters;
