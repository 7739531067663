export const AE2_URL = process.env.REACT_APP_AE2_URL;

export const SPORT_LEAGUES = {
  NBA: 'NBA',
  NHL: 'NHL',
  MLB: 'MLB',
  NFL: 'NFL',
};

export const FEATURE_TOGGLES = {
  teamPages: true,
  playerPages: false,
  gamePages: false,
}