import React from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import API_URLS from '../../../../api/api_urls';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import GolfTournamentLayout from '../../../compounds/GolfTournamentLayout/GolfTournamentLayout';
import { useFetchTournamentDetail, useFetchTournamentResults } from '../../../hooks/useFetch';

function GolfTournament() {
  const { tournamentId } = useParams();

  const apiUrl = API_URLS.golf;

  const { data: tournamentDetailData, isLoading: isProfileLoading, error: profileError } = useFetchTournamentDetail(tournamentId || '', apiUrl.tournament_detail);
  const { data: tournamentResultsData, isLoading: isStatsLoading, error: statsError } = useFetchTournamentResults(tournamentId || '', apiUrl.tournament_results);

  if (isProfileLoading || isStatsLoading) {
    return <span>Loading...</span>
  }

  if (profileError && profileError instanceof Error) {
    return <span>Error: {profileError.message}</span>
  }

  if (statsError && statsError instanceof Error) {
    return <span>Error: {statsError.message}</span>
  }

  if (!tournamentDetailData || !tournamentResultsData) {
    return <span>Error: Api failed to respond</span>
  }

  return (
    <Dashboard>
      <Container sx={{ py: 2 }} maxWidth="xl">
        <GolfTournamentLayout tournamentDetails={tournamentDetailData} tournamentResultsData={tournamentResultsData} />
      </Container>
    </Dashboard>
  );
}

export default GolfTournament;
