import { StatFilter, StatFilterType } from "../../../compounds/EliasStatsFilter/filter-stats-utils";

export type FinderSelectOptionType = {
  id: string;
  label: string;
};

export const groupByOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'None' },
  { id: 'player', label: 'Player career instances' },
  { id: 'player-season', label: 'Player within a season' },
  { id: 'player-franchise', label: 'Player with a franchise' },
  { id: 'player-opponent', label: 'Player against an opponent' },
  // { id: 'player-franchise-season', label: 'Player with a franchise in a season' },
  // { id: 'player-opponent-season', label: 'Player against an opponent in a season' },
];

export const DEFAULT_GROUP_BY_STATUS = groupByOptions[0].id;

export const statsFilters: StatFilter[] = [
  {
    id: 'round_score',
    abbr: 'Round Score',
    type: StatFilterType.equality,
  },
  {
    id: 'score_to_par',
    abbr: 'Score To Par',
    type: StatFilterType.equality,
  },
  {
    id: 'front_9_score',
    abbr: 'Front 9 Score',
    type: StatFilterType.equality,
  },
  {
    id: 'front_9_score_to_par',
    abbr: 'Front 9 Score To Par',
    type: StatFilterType.equality,
  },
  {
    id: 'back_9_score',
    abbr: 'Back 9 Score',
    type: StatFilterType.equality,
  },
  {
    id: 'back_9_score_to_par',
    abbr: 'Back 9 Score To Par',
    type: StatFilterType.equality,
  },
  {
    id: 'any_9_score',
    abbr: 'Any 9 Score',
    type: StatFilterType.equality,
  },
  {
    id: 'any_9_score_to_par',
    abbr: 'Any 9 Score To Par',
    type: StatFilterType.equality,
  },
  {
    id: 'golfer_age',
    abbr: 'Golfer Age',
    type: StatFilterType.equality,
  },
  {
    id: 'golfer_height',
    abbr: 'Golfer Height (in.)',
    type: StatFilterType.equality,
  },
  {
    id: 'golfer_career_wins',
    abbr: '# of Career Wins',
    type: StatFilterType.equality,
  },
  {
    id: 'golfer_career_wins_at_the_time',
    abbr: '# of Career Wins - At The Time',
    type: StatFilterType.equality,
  },
  {
    id: 'golfer_career_majors',
    abbr: '# of Career Major Wins',
    type: StatFilterType.equality,
  },
  {
    id: 'golfer_career_majors_at_the_time',
    abbr: '# of Career Major Wins - At The Time',
    type: StatFilterType.equality,
  },
  {
    id: 'owgr',
    abbr: 'Official World Golf Ranking',
    type: StatFilterType.equality,
  },
  {
    id: 'birdies_made',
    abbr: 'Birdies Made',
    type: StatFilterType.equality,
  },
  {
    id: 'eagles_made',
    abbr: 'Eagles Made',
    type: StatFilterType.equality,
  },
  {
    id: 'bogeys_made',
    abbr: 'Bogeys Made',
    type: StatFilterType.equality,
  },
  {
    id: 'holes_in_one_made',
    abbr: 'Holes in One Made',
    type: StatFilterType.equality,
  },
  {
    id: 'double_bogeys_made',
    abbr: 'Double Bogeys Made',
    type: StatFilterType.equality,
  },
  {
    id: 'triple_bogeys_made',
    abbr: 'Triple Bogeys Made',
    type: StatFilterType.equality,
  },
  {
    id: 'quad_or_worse_made',
    abbr: 'Quad or Worse Made',
    type: StatFilterType.equality,
  },
  {
    id: 'holes_under_par',
    abbr: 'Holes Under Par',
    type: StatFilterType.equality,
  },
  {
    id: 'holes_over_par',
    abbr: 'Holes Over Par',
    type: StatFilterType.equality,
  },
  {
    id: 'greens_in_regulation',
    abbr: 'Greens In Regulation',
    type: StatFilterType.equality,
  },
  {
    id: 'sand_saves',
    abbr: 'Sand Saves',
    type: StatFilterType.equality,
  },
  {
    id: 'sand_save_pct',
    abbr: 'Sand Save %',
    type: StatFilterType.equality,
  },
  {
    id: 'scrambling_pct',
    abbr: 'Scrambling %',
    type: StatFilterType.equality,
  },
  {
    id: 'average_driving_distance',
    abbr: 'Avg. Driving Distance',
    type: StatFilterType.equality,
  },
  {
    id: 'number_of_putts',
    abbr: '# of Putts',
    type: StatFilterType.equality,
  },
  {
    id: 'putts_per_gir',
    abbr: 'Putts Per GIR',
    type: StatFilterType.equality,
  },
  {
    id: 'three_putts',
    abbr: '3-Putts',
    type: StatFilterType.equality,
  },
  {
    id: 'one_putts',
    abbr: '1-Putts',
    type: StatFilterType.equality,
  },
  {
    id: 'total_distance_of_putts_made',
    abbr: 'Total Distance (ft.) of Putts Made',
    type: StatFilterType.equality,
  },
  {
    id: 'strokes_gained',
    abbr: 'Strokes Gained - Overall',
    type: StatFilterType.equality,
  },
  {
    id: 'strokes_gained_tee',
    abbr: 'Strokes Gained - Off The Tee',
    type: StatFilterType.equality,
  },
  {
    id: 'strokes_gained_tee_to_green',
    abbr: 'Strokes Gained - Tee To Green',
    type: StatFilterType.equality,
  },
  {
    id: 'strokes_gained_approach',
    abbr: 'Strokes Gained - Approach',
    type: StatFilterType.equality,
  },
  {
    id: 'strokes_gained_around_the_green',
    abbr: 'Strokes Gained - Around The Green',
    type: StatFilterType.equality,
  },
  {
    id: 'strokes_gained_putting',
    abbr: 'Strokes Gained - Putting',
    type: StatFilterType.equality,
  },
];