import theme from '../../utils/theme/theme';

const DataGridStyles = {
  '.MuiDataGrid-main': {
    color: theme.palette.custom.mediumGrey,
    fontSize: '12px',
  },
  '.MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
    display: 'none',
  },
  '.MuiDataGrid-columnHeadersInner': {
    backgroundColor: theme.palette.custom.lighterGrey,
    borderBottom: '2px solid rgba(224, 224, 224, 1)',
  },
  '.MuiDataGrid-columnHeaders': {
    borderBottom: 'none !important',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    textTransform: 'capitalize',
  },
  '& .MuiDataGrid-cell:hover, & .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.custom.blueGrey,
  },
  '& .Mui-selected': {
    backgroundColor: 'white !important',
  },
  marginY: '8px',
};

export default DataGridStyles;
