import React from 'react';
import { InputAdornment, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import CancelRounded from '@mui/icons-material/CancelRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import { GolfTournamentInfo } from '../../../api/types';
import './GolfTournamentFilter.scss';
import { useFetchGolfTournaments } from '../../hooks/useFetch';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';

interface EliasNameFilterProps {
  label: string;
  actionType: string;
  actionObject: string;
  apiUrl: string;
}

const StyledAutocomplete = styled(Autocomplete)((props: { textcolor: string, weight: number }) => ({
  '& .MuiInputBase-root': {
    paddingLeft: '35px !important',
    '& .MuiAutocomplete-input': {
      color: props.textcolor,
      fontWeight: props.weight,
    },
    '& .MuiAutocomplete-endAdornment': {    
      '& .MuiAutocomplete-clearIndicator': {
        '& .cancel-icon': {
          color: props.textcolor,
          visibility: 'visible',
        }
      }
    },
  },
}));

function GolfTournamentFilter({
  label,
  actionType,
  actionObject,
  apiUrl
}: EliasNameFilterProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<GolfTournamentInfo[]>([]);

  const { data: tournamentData, refetch: refetchTournaments } = useFetchGolfTournaments(inputValue, apiUrl);

  const handleChange = (_event: any, newValue: GolfTournamentInfo | null) => {
    console.log(newValue);
    setOptions(newValue ? [newValue, ...options] : options);
    filterDispatchContext({
      type: actionType,
      [actionObject]: newValue,
      searchEnabled: true,
    });
  };

  const handleInputChange = (
    _event: any,
    newInputValue: string,
  ) => {
    setInputValue(newInputValue);
    if (newInputValue !== inputValue) refetchTournaments();
  };

  const textColor = filterContext[actionObject] && 
    filterContext[actionObject].id ? '#175CD3' : '#101828';

  const fontweight = filterContext[actionObject] && 
    filterContext[actionObject].id ? 500 : 400;

  return (
    <Box>
      <StyledAutocomplete
        id="elias-golf-tournament-filter"
        data-testid="elias-golf-tournament-filter"
        options={tournamentData?.results ?? []}
        value={filterContext[actionObject]}
        inputValue="PGA Championship"
        includeInputInList
        filterOptions={(x) => x}
        isOptionEqualToValue={(option: any) =>
          option.id === filterContext[actionObject]?.id
        }
        noOptionsText="No matches found."
        getOptionLabel={(option: any) => option.display_name}
        onChange={(event: any, value: any) => handleChange(event, value)}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField {...params} placeholder={label} InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <EmojiEventsRoundedIcon className="group-icon" />
              </InputAdornment>
            ),
          }}/>
        )}
        renderOption={(props, option: any) => (
          <li {...props} key={option.id}>
            {option.display_name}
          </li>
        )}
        popupIcon={
          <ExpandMoreIcon />
        }
        forcePopupIcon={!filterContext[actionObject]?.id}
        clearIcon={
          <CancelRounded className='cancel-icon' />
        }
        textcolor={textColor}
        weight={fontweight}
      />
    </Box>
  );
}

export default GolfTournamentFilter;
