import {
  DEFAULT_PLAYER_GAME_ORDERING,
  baseFetchPlayerGameStats,
  baseFetchPlayerSpanStats,
  baseFetchPlayerStreakStats,
} from './api';
import API_URLS from './api_urls';

export const fetchNcaaMbbPlayerGameStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => baseFetchPlayerGameStats(
  API_URLS.ncaambb.player_game_finder, filters, signOut, page, sortBy, uuid
);

export const fetchNcaaMbbPlayerStreakStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
) => baseFetchPlayerStreakStats(
  API_URLS.ncaambb.player_streak_finder, filters, signOut, page, sortBy
);

export const fetchNcaaPlayerSpanStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
) => baseFetchPlayerSpanStats(
  API_URLS.ncaambb.player_span_finder, filters, signOut, page, sortBy
);
