import React from "react";
import Button from "@mui/material/Button";
import { useSignOut } from 'react-auth-kit'
import { datadogRum } from '@datadog/browser-rum';
import { doLogout } from '../../../api/auth';

function LogOut() {
  const signOut = useSignOut();

  const logout = () => {
    doLogout();
    datadogRum.clearUser();
    signOut();
  };

  return (
    <Button onClick={logout} variant='contained'>Log out</Button>
  )
};

export default LogOut;
