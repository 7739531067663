import React from 'react';
import Grid from '@mui/material/Grid';
import { EqualitySignType, EqualitySigns } from '../../utils/stats/stats-utils';
import {
  EliasEqualityButtons,
  EliasBooleanButtons,
} from '../../atoms/EliasEqualityButtons/EliasEqualityButtons';
import {
  StatExplanation,
  StatInfoProps,
  StatNameAndAbbr,
  StatYear,
} from './EliasStatInfo';
import './EliasOptionList.scss';
import useKeyPress from '../../hooks/useKeyPress';
import { EqualityRowContainerStyles } from './StyleOverrides';
import EliasNumericInput from '../../atoms/EliasInput/EliasNumericInput';
import EliasBooleanInput from '../../atoms/EliasInput/EliasBooleanInput';

export function EliasBasicOptionRow({ statFilter, displayYearInfo }: StatInfoProps) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <StatNameAndAbbr statFilter={statFilter} />
      {displayYearInfo && <StatYear statFilter={statFilter} displayYearInfo={displayYearInfo} />}
    </Grid>
  );
}

interface EqualityOptionRowProps extends StatInfoProps {
  onButtonClick: (
    sign: EqualitySignType,
    filterId: string,
    filterAbbreviation: string
  ) => void;
  value: string;
  onValueChange: (
    value: string,
    filterId: string,
    filterAbbreviation: string
  ) => void;
  focusedOption: string;
  ariaLabel: string;
  selectedEquality: string;
  selected: boolean;
  type: 'equality' | 'boolean';
  displayYearInfo: boolean;
}

type Visibility = 'visible' | 'hidden';

export function EliasEqualityOptionRow({
  statFilter,
  onButtonClick,
  value,
  onValueChange,
  focusedOption,
  ariaLabel,
  selectedEquality,
  selected,
  type,
  displayYearInfo,
}: EqualityOptionRowProps) {
  const enterPress = useKeyPress('Enter');

  const [equalityVisibility, setEqualityVisibility] =
    React.useState<Visibility>('hidden');
  const [isEditingValue, setIsEditingValue] = React.useState(false);

  React.useEffect(() => {
    const isStatFocused = selected || focusedOption === statFilter.abbr;
    setEqualityVisibility(isStatFocused ? 'visible' : 'hidden');
  }, [focusedOption, statFilter.abbr, selected]);

  const handleValueChange = (inputValue: string) => {
    onValueChange(inputValue, statFilter.id, statFilter.abbr);
  };

  const handleMouseEnter = () => {
    if (selected) return;
    setEqualityVisibility('visible');
  };

  const handleMouseLeave = () => {
    if (selected) return;
    setEqualityVisibility('hidden');
  };

  const toggleIsEditing = () => setIsEditingValue((prevState) => !prevState);

  const handleButtonClick = (
    sign: EqualitySignType,
    filterId: string,
    filterAbbreviation: string
  ) => {
    setIsEditingValue(false);
    onButtonClick(sign, filterId, filterAbbreviation);
  };

  React.useEffect(() => {
    if (enterPress && focusedOption) toggleIsEditing();
  }, [enterPress, focusedOption]);

  React.useEffect(() => {
    if (focusedOption === undefined) setIsEditingValue(false);
  }, [focusedOption, isEditingValue]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={EqualityRowContainerStyles}
      rowSpacing={2}
    >
      <StatNameAndAbbr statFilter={statFilter} />
      {type === 'equality' && (
        <Grid item style={{ visibility: equalityVisibility }}>
          <EliasEqualityButtons
            selected={selectedEquality}
            onButtonClick={handleButtonClick}
            filterId={statFilter.id}
            filterAbbreviation={statFilter.abbr}
            onClickAway={toggleIsEditing}
          />
          <EliasNumericInput
            id={statFilter.abbr}
            value={value}
            onChange={handleValueChange}
            ariaLabel={ariaLabel}
            focusedOption={focusedOption}
            isEditing={isEditingValue}
          />
        </Grid>
      )}
      {type === 'boolean' && (
        <Grid item style={{ visibility: equalityVisibility }}>
          <EliasBooleanButtons
            selected={EqualitySigns['=']}
            onButtonClick={handleButtonClick}
            filterId={statFilter.id}
            filterAbbreviation={statFilter.abbr}
            onClickAway={toggleIsEditing}
          />
          <EliasBooleanInput
            value={value}
            onChange={handleValueChange}
            ariaLabel={ariaLabel}
            focusedOption={focusedOption}
            id={statFilter.abbr}
            isEditing={isEditingValue}
          />
          <StatExplanation statFilter={statFilter} />
        </Grid>
      )}
      {displayYearInfo && <StatYear statFilter={statFilter} displayYearInfo={displayYearInfo} />}
    </Grid>
  );
}
