export type FinderSelectOptionType = {
  id: string;
  label: string;
};

// BEGIN HOOPS

export const activeStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'active', label: 'Active Players' },
  { id: 'not-active', label: 'Not Active' },
];

export const rookieStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'rookie', label: 'Rookie' },
  { id: 'not-rookie', label: 'Not Rookie' },
];

export const homeAwayOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'away', label: 'Away' },
  { id: 'home', label: 'Home' },
  { id: 'neutral', label: 'Neutral' }
];

export const winLossStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'win', label: 'Win' },
  { id: 'loss', label: 'Loss' },
];

export const overtimeStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'overtime', label: 'OT Only' },
  { id: 'no-overtime', label: 'No OT' },
];

export const groupByOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'None' },
  { id: 'player', label: 'Player career instances' },
  { id: 'player-season', label: 'Player within a season' },
  { id: 'player-franchise', label: 'Player with a franchise' },
  { id: 'player-opponent', label: 'Player against an opponent' },
  // { id: 'player-franchise-season', label: 'Player with a franchise in a season' },
  // { id: 'player-opponent-season', label: 'Player against an opponent in a season' },
];

// END HOOPS

// BEGIN GOLF

export const majorChampionStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'major-champion-any', label: 'Major Champion' },
  { id: 'major-champion-at-the-time', label: 'Major Champion At The Time' },
  { id: 'major-champion-multiple-any', label: '2x Major Champion or More' },
  { id: 'major-champion-multiple-at-the-time', label: '2x Major Champion or More - At The Time' },
  { id: 'non-major-champion', label: 'Non-Major Champions Only' },
];

export const defendingChampionStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'defending-champion', label: 'Defending Champion' },
  { id: 'any-former-champion', label: 'Former Champion' },
];

export const firstAppearanceStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'defending-champion', label: 'First Tournament Appearance' },
  { id: 'any-former-champion', label: 'Appeared Previously' },
];

export const professionalStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'pro', label: 'Any Professional' },
  { id: 'touring-pro', label: 'Touring Professional' },
  { id: 'club-pro', label: 'Club Professional' },
  { id: 'amateur', label: 'Amateur' },
];

export const handednessOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'righty', label: 'Lefty' },
  { id: 'lefty', label: 'Righty' },
];

export const nationalityOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'american', label: 'American' },
  { id: 'non-american', label: 'Non-American' },
];

export const golfRookieOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'rookie', label: 'Rookie' },
  { id: 'non-rookie', label: 'Non-Rookie' },
];

export const cutOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'made-cut', label: 'Made Cut' },
  { id: 'missed-cut', label: 'Missed Cut' },
];

export const playoffOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'playoff', label: 'Playoff' },
  { id: 'non-playoff', label: 'Non-Playoff' },
];

export const finishOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'won', label: 'Won Tournament' },
  { id: 'top-two', label: 'Finished Top 2' },
  { id: 'top-three', label: 'Finished Top 3' },
  { id: 'top-five', label: 'Finished Top 5' },
  { id: 'top-ten', label: 'Finished Top 10' },
  { id: 'top-twenty', label: 'Finished Top 20' },
  { id: 'top-forty', label: 'Finished Top 40' },
];

export const fairwayGrassOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'bentgrass', label: 'Bentgrass' },
  { id: 'bermuda', label: 'Bermuda' },
  { id: 'fescue', label: 'Fescue' },
  { id: 'poa-annua', label: 'Poa Annua' },
  { id: 'rye', label: 'Rye' },
  { id: 'zoysia', label: 'Zoysia' },
];

export const greensGrassOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'bentgrass', label: 'Bentgrass' },
  { id: 'bermuda', label: 'Bermuda' },
  { id: 'fescue', label: 'Fescue' },
  { id: 'poa-annua', label: 'Poa Annua' },
  { id: 'rye', label: 'Rye' },
  { id: 'zoysia', label: 'Zoysia' },
];

// END GOLF

export const DEFAULT_ACTIVE_STATUS = activeStatusOptions[0].id;
export const DEFAULT_HOME_AWAY = homeAwayOptions[0].id;
export const DEFAULT_ROOKIE_STATUS = rookieStatusOptions[0].id;
export const DEFAULT_WIN_LOSS_STATUS = winLossStatusOptions[0].id;
export const DEFAULT_OVERTIME_STATUS = overtimeStatusOptions[0].id;
export const DEFAULT_GROUP_BY_STATUS = groupByOptions[0].id;
export const DEFAULT_MAJOR_CHAMPION_STATUS = majorChampionStatusOptions[0].id;
export const DEFAULT_DEFENDING_CHAMPION_STATUS = defendingChampionStatusOptions[0].id;
export const DEFAULT_FIRST_APPEARANCE_STATUS = firstAppearanceStatusOptions[0].id;
export const DEFAULT_PROFESSIONAL_STATUS = professionalStatusOptions[0].id;
export const DEFAULT_HANDEDNESS_STATUS = handednessOptions[0].id;
export const DEFAULT_NATIONALITY_STATUS = nationalityOptions[0].id;
export const DEFAULT_CUT_STATUS = cutOptions[0].id;
export const DEFAULT_PLAYOFF_STATUS = playoffOptions[0].id;
export const DEFAULT_FINISH_STATUS = finishOptions[0].id;
export const DEFAULT_FAIRWAY_GRASS_STATUS = fairwayGrassOptions[0].id;
export const DEFAULT_GREENS_GRASS_STATUS = greensGrassOptions[0].id;

