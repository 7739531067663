import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import StyledTable from '../../atoms/StyledTable/StyledTable';
import { FinderSelectOptionType } from '../FastFilters/constants';
import FinderSelect from '../../atoms/FinderSelect/FinderSelect';
import TournamentFactCard from './TournamentFactCard';
import FactCard from '../../atoms/FactCard/FactCard';
import API_URLS from '../../../api/api_urls';
import { useFetchFranchiseSeason } from '../../hooks/useFetch';
import { FranchisePlayerSeasonStats, FranchiseSeasonType, FranchiseTeamGameType, SeasonProfileTournamentType, TournamentSummaryDataType } from '../../types/franchise';

const playerStatsHeaders = [
  'Player',
  '#',
  'Height',
  'Weight',
  'Class',
  'GP',
  'PTS',
  'REB',
  'AST',
  'BLK',
  'STL',
  'FG%',
  '3PT%',
  'FT%',
];

const playerStatsMap = {
  'Player': 'name',
  '#': 'number',
  'Height': 'height',
  'Weight': 'weight',
  'Class': 'year',
  'GP': 'gamesPlayed',
  'PTS': 'points',
  'REB': 'rebounds',
  'AST': 'assists',
  'BLK': 'blocks',
  'STL': 'steals',
  'FG%': 'fieldGoalPct',
  '3PT%': 'threePointFieldGoalPct',
  'FT%': 'freeThrowPct',
};

const scheduleStatsHeaders = [
  'Game',
  'Date',
  'At',
  'Opp',
  'Game Type',
  'Result',
  'STL',
  'BLK',
  'TO',
  'PF',
  'OREB',
  'DREB',
  'REB',
  'AST',
];

const scheduleStatsMap = {
  'Game': 'row',
  'Date': 'date',
  'At': 'homeAway',
  'Opp': 'opponentName',
  'Game Type': 'gameType',
  'Result': 'result',
  'STL': 'steals',
  'BLK': 'blocks',
  'TO': 'turnovers',
  'PF': 'personalFouls',
  'OREB': 'offensiveRebounds',
  'DREB': 'defensiveRebounds',
  'REB': 'rebounds',
  'AST': 'assists',
};

const seasonSelectOptions: FinderSelectOptionType[] =  [
  { id: 'any', label: 'Choose Season' },
  { id: '2022', label: '2022-23' },
  { id: '2021', label: '2021-22' },
];

interface TournamentGame {
  id: number;
  round: string;
  result: string;
  teamScore: number;
  opponentScore: number;
  opponentName: string;
}

const mapTournament = (teamGames: FranchiseTeamGameType[], info?: SeasonProfileTournamentType): TournamentSummaryDataType | undefined => {
  if (!info) return undefined;
  
  const games = info.games.map((gameId: number) => {
    const teamGame = teamGames.find((game) => game.game_id === gameId);
    if (!teamGame) return undefined;
    return {
      id: gameId,
      round: teamGame.round || '',
      result: teamGame.result.result,
      teamScore: teamGame.result.score,
      opponentScore: teamGame.result.opponent_score,
      opponentName: teamGame.opponent.name,
    }
  });
  const cleanedGames = games.filter((g): g is TournamentGame => g !== undefined);
  return {
    seed: info.seed,
    region: info.region,
    games: cleanedGames,
  };
};

const mapPlayerStats = (data: FranchisePlayerSeasonStats[]) =>
  data.map(row => (
    {
      id: row.player_id,
      name: row.name,
      number: row.uniform_number,
      height: row.height,
      weight: row.weight,
      year: row.class,
      gamesPlayed: row.regular_season.games,
      points: row.regular_season.points,
      rebounds: row.regular_season.total_rebounds,
      assists: row.regular_season.assists,
      blocks: row.regular_season.blocks,
      steals: row.regular_season.steals,
      fieldGoalPct: row.regular_season.field_goal_percentage,
      threePointFieldGoalPct: row.regular_season.three_point_field_goal_percentage,
      freeThrowPct: row.regular_season.free_throw_percentage,
    }
  ));

const mapTeamGames = (data: FranchiseTeamGameType[]) =>
  data.map(row => (
    {
      id: row.game_id,
      date: row.date,
      homeAway: row.home_road,
      opponentName: row.opponent.name,
      gameType: row.game_type,
      result: row.result.result,
      steals: row.stats.steals,
      blocks: row.stats.blocks,
      turnovers: row.stats.turnovers,
      personalFouls: row.stats.personal_fouls,
      rebounds: row.stats.total_rebounds,
      offensiveRebounds: row.stats.offensive_rebounds,
      defensiveRebounds: row.stats.defensive_rebounds,
      assists: row.stats.assists,
    }
  ));

interface IProps {
  teamId: string;
}

function TeamSeasonsTab({ teamId }: IProps) {
  const [selectedSeason, setSelectedSeason] = React.useState('2022');

  const apiUrl = API_URLS.ncaambb;

  const { data: franchiseDetailData, isLoading, error } = useFetchFranchiseSeason(teamId, selectedSeason, apiUrl.franchise_season_profile);

  const handleSeasonChange = (selection: string) => {
    setSelectedSeason(selection);
  };

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (error && error instanceof Error) {
    return <span>Error: {error.message}</span>
  }

  const franchiseSeasonData: FranchiseSeasonType | undefined = franchiseDetailData && franchiseDetailData.results[0];
  if (!franchiseSeasonData) {
    return <span>Error: Data failed to load</span>
  }
  
  const seasonProfile = franchiseSeasonData.season_profile;
  const teamGames = franchiseSeasonData.team_games;
  const playerSeasonStats = franchiseSeasonData.player_season_stats;
  
  const ncaaTournamentData = mapTournament(teamGames, seasonProfile.tournaments.ncaa);
  const conferenceTournamentData = mapTournament(teamGames, seasonProfile.tournaments.conference);
  const mappedPlayerStats = mapPlayerStats(playerSeasonStats);
  const mappedTeamGames = mapTeamGames(teamGames);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <FinderSelect
            label='Season'
            value={selectedSeason}
            options={seasonSelectOptions}
            handleSelection={handleSeasonChange}
            disabled
          />
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h6" fontWeight="600">{seasonProfile.commone_name} Men&apos;s Basketball History</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2} pl={2}>
          <FactCard label='Overall Record' values={[`${seasonProfile.record.overall.wins} - ${seasonProfile.record.overall.losses}`]} />
          <FactCard label='Conference Record' values={[`${seasonProfile.record.conference.wins} - ${seasonProfile.record.conference.losses}`]} />
          <FactCard label='Rank' values={[`${seasonProfile.ranking ? seasonProfile.ranking.final : ''}`]} />
        </Grid>
        <Grid item xs={5}>
          { ncaaTournamentData && <TournamentFactCard label='NCAA Tournament' data={ncaaTournamentData} /> }
          { conferenceTournamentData && <TournamentFactCard label='Conference Tournament' data={conferenceTournamentData} /> }
        </Grid>
      </Grid>
      <Grid container mt={5}>
        { 
          // @todo: this is weird that the tables are spilling over the right edge of the page but theres not time to look into it.
          // adding margin for now and will need to investigate at a later date
        }
        <Grid item xs={12} marginRight={5}>
          <StyledTable
            uniqueRowKey='id'
            headers={playerStatsHeaders}
            data={mappedPlayerStats}
            dataMap={playerStatsMap}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={8}><h5 style={{ fontSize: '1.4em', margin: 0, }}>Player Stats</h5></TableCell>
                <TableCell colSpan={6} align='right'>
                  <span style={{ fontSize: '1.25em', margin: 0, fontWeight: 600, marginRight: 20, }}>Head Coach</span>
                  <span style={{ fontSize: '1.15em', margin: 0, }}>Jack Castleberry</span>
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>

      <Grid container mt={5}>
        <Grid item xs={12} marginRight={5}>
          <StyledTable uniqueRowKey='id' headers={scheduleStatsHeaders} data={mappedTeamGames} dataMap={scheduleStatsMap} />
        </Grid>
      </Grid>
    </>
  );
}

export default TeamSeasonsTab;
