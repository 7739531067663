import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../compounds/Common/Header';
import ROUTER_URLS from '../utils/router/urls';

function Home() {
  return (
    <>
      <Header title="AccessElias" />
      {/* Placeholder link to tool */}
      <Grid>
        <Link to={ROUTER_URLS.nba.playerGameFinderSearch}>Nba Player Game Finder</Link>
      </Grid>
      <Grid>
        <Link to={ROUTER_URLS.nba.playerStreakFinderSearch}>Nba Player Streak Finder</Link>
      </Grid>
      <Grid>
        <Link to={ROUTER_URLS.nba.playerSpanFinderSearch}>Nba Player Span Finder</Link>
      </Grid>
      <Grid>
        <Link to={ROUTER_URLS.ncaambb.playerGameFinderSearch}>Ncaa Mbb Player Player Game Finder</Link>
      </Grid>
      <Grid>
        <Link to={ROUTER_URLS.ncaambb.playerStreakFinderSearch}>Ncaa Mbb Player Streak Finder</Link>
      </Grid>
      <Grid>
        <Link to={ROUTER_URLS.ncaambb.playerSpanFinderSearch}>Ncaa Mbb Player Span Finder</Link>
      </Grid>
    </>
  );
}

export default Home;
