import { GridValidRowModel } from '@mui/x-data-grid/models';
import { DEFAULT_PAGE_SIZE } from '../../../../api/api';
import { GroupedPlayerInstance, GolferTournamentResult, GolferTournamentTableRow } from '../../../../api/types';

export const generateRowNumber = (
  rowID: number,
  page: number,
  pageSize = DEFAULT_PAGE_SIZE
) => (page === 0 ? rowID + 1 : pageSize * page + rowID + 1);

export const dedupeRows = (allRows: GridValidRowModel) => {
  const ids = allRows.map((o: GridValidRowModel) => o.id);
  const filteredRows = allRows.filter(
    ({ id }: { id: string }, index: number) => !ids.includes(id, index + 1)
  );
  console.log("filtered_rows: ");
  console.log(filteredRows);
  return filteredRows;
};

export const mapUngroupedRows = (results: GolferTournamentResult[], page: number): GolferTournamentTableRow[] =>
  results.map((row: GolferTournamentResult, index: number) => ({
    id: row.id,
    rank: generateRowNumber(index, page),
    golfer__display_name: row.golfer.display_name,
    golfer__player_id: row.golfer.id,
    golfer__nationality: row.golfer.nationality,
    age: row.age,
    tournament__name: row.tournament.name,
    tournament__year: row.tournament.year,
    tournament__golf_course: row.tournament.golf_course,
    tournament__tournament_class: row.tournament.tournament_class,
    finish: row.finish,
    round_number: row.round_number,
    round_score: row.round_score,
    round_score_to_par: row.round_score_to_par,
    tournament_score: row.tournament_score,
    tournament_score_to_par: row.tournament_score_to_par,
    hole_1_score: row.holes.hole1.score,
    hole_2_score: row.holes.hole2.score,
    hole_3_score: row.holes.hole3.score,
    hole_4_score: row.holes.hole4.score,
    hole_5_score: row.holes.hole5.score,
    hole_6_score: row.holes.hole6.score,
    hole_7_score: row.holes.hole7.score,
    hole_8_score: row.holes.hole8.score,
    hole_9_score: row.holes.hole9.score,
    hole_10_score: row.holes.hole10.score,
    hole_11_score: row.holes.hole11.score,
    hole_12_score: row.holes.hole12.score,
    hole_13_score: row.holes.hole13.score,
    hole_14_score: row.holes.hole14.score,
    hole_15_score: row.holes.hole15.score,
    hole_16_score: row.holes.hole16.score,
    hole_17_score: row.holes.hole17.score,
    hole_18_score: row.holes.hole18.score,
    hole_1_score_to_par: row.holes.hole1.score_to_par,
    hole_2_score_to_par: row.holes.hole2.score_to_par,
    hole_3_score_to_par: row.holes.hole3.score_to_par,
    hole_4_score_to_par: row.holes.hole4.score_to_par,
    hole_5_score_to_par: row.holes.hole5.score_to_par,
    hole_6_score_to_par: row.holes.hole6.score_to_par,
    hole_7_score_to_par: row.holes.hole7.score_to_par,
    hole_8_score_to_par: row.holes.hole8.score_to_par,
    hole_9_score_to_par: row.holes.hole9.score_to_par,
    hole_10_score_to_par: row.holes.hole10.score_to_par,
    hole_11_score_to_par: row.holes.hole11.score_to_par,
    hole_12_score_to_par: row.holes.hole12.score_to_par,
    hole_13_score_to_par: row.holes.hole13.score_to_par,
    hole_14_score_to_par: row.holes.hole14.score_to_par,
    hole_15_score_to_par: row.holes.hole15.score_to_par,
    hole_16_score_to_par: row.holes.hole16.score_to_par,
    hole_17_score_to_par: row.holes.hole17.score_to_par,
    hole_18_score_to_par: row.holes.hole18.score_to_par,
    front_9_score: row.front_9_score,
    back_9_score: row.back_9_score,
    front_9_score_to_par: row.front_9_score_to_par,
    back_9_score_to_par: row.back_9_score_to_par,

    // the following fields will not display but are needed for rendering
    // other things
    tournament_id: row.tournament.id,
  }));

export const getGameDateFromID = (gameID: number) => {
  if (!gameID) return '';
  // Converts the GameID which is in the YYYYMMMDDGG format to game date format YYYY-MM-DD
  const date = gameID.toString().slice(0, -2);
  const formattedDate = date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
  return formattedDate;
};

export const mapPlayerRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    player_id: row.player_id,
  }));

export const mapPlayerSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    season: row.season,
    player_id: row.player_id,
  }));  

export const mapPlayerFranchiseRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    franchise_name: row.franchise?.full_name,
    player_id: row.player_id,
  }));

export const mapPlayerOpponentRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    opponent_name: row.opponent?.full_name,
    player_id: row.player_id,
  }));

export const mapPlayerFranchiseSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    franchise_name: row.franchise?.full_name,
    season: row.season,
    player_id: row.player_id,
  }));

export const mapPlayerOpponentSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    opponent_name: row.opponent?.full_name,
    season: row.season,
    player_id: row.player_id,
  }));  

export const mapRows = (
  results: GolferTournamentResult[] | GroupedPlayerInstance[],
  groupByColumn: string,
  page: number
) => {
  switch (groupByColumn) {
    case 'player':
      return mapPlayerRows(results as GroupedPlayerInstance[], page);
    case 'player-season':
      return mapPlayerSeasonRows(results as any, page);
    case 'player-franchise':
      return mapPlayerFranchiseRows(results as any, page);
    case 'player-opponent':
      return mapPlayerOpponentRows(results as any, page);
    case 'player-franchise-season':
      return mapPlayerFranchiseSeasonRows(results as any, page);
    case 'player-opponent-season':
      return mapPlayerOpponentSeasonRows(results as any, page);
    default:
      return mapUngroupedRows(dedupeRows(results) as GolferTournamentResult[], page);
  };
};
