import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { useFilterDispatchContext } from '../../../contexts/FilterContext';
import PlayerSpanFilters from '../../../compounds/PlayerSpanFilters/PlayerSpanFilters';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories, gameModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaMbbPlayerFiltersLayout from '../../../compounds/ncaambb/NcaaMbbPlayerFiltersLayout';

const PAGE_TITLE = 'Player Span Finder';
const PAGE_SUBTITLE =
  'Search the most complete and accurate NCAA MBB database for player game stats, that occurred within a span of games.';

function PlayerSpanFinderSearch() {
  const navigate = useNavigate();
  const filterDispatchContext: any = useFilterDispatchContext();

  const resetFilters = () => {
    filterDispatchContext({
      type: 'resetFilters',
    });
  };

  const handleDone = () => {
    filterDispatchContext({
      type: 'changeSearchStatus',
      searchEnabled: false,
    });
    navigate(ROUTER_URLS.ncaambb.playerSpanFinderResults, {
      state: {},
    });
  };

  const pageSpecificSearchEnabled = (context: ContextFilterType): boolean =>
    Boolean(context.spanLength) && context.spanLength > 1;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const topModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
    />
  );

  return (
    <Dashboard>
      <Container sx={{ py: 2 }} maxWidth="xl">
        <Grid
          container
          direction="column"
          spacing={4}
          className="player-game-finder-container"
        >
          <Grid item>
            <Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />
          </Grid>
          <Grid item>
            <NcaaMbbPlayerFiltersLayout
              resetFilters={resetFilters}
              handleDone={handleDone}
              statsFilterPartial={statsFilterPartial}
              pageSpecificSearchEnabled={pageSpecificSearchEnabled}
              apiUrls={API_URLS.ncaambb}
              topModalPartial={topModalPartial}
              bottomModalPartial={bottomModalPartial}
            >
              <PlayerSpanFilters />
            </NcaaMbbPlayerFiltersLayout>
          </Grid>
        </Grid>
      </Container>
    </Dashboard>
  );
}

export default PlayerSpanFinderSearch;
