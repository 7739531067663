import { AE2_URL, SPORT_LEAGUES } from '../common/constants';

export const BASE_NBA_URL = `${AE2_URL}/${SPORT_LEAGUES.NBA}/`;

const EXTERNAL_URLS = {
  NBA: {
    playerProfile: `${BASE_NBA_URL}Player/Profile/:playerId:#`,
    franchiseProfile: `${BASE_NBA_URL}Team/Profile/:franchiseId:#`,
    boxscore: `${BASE_NBA_URL}boxscore/game/:gameId:`,
  },
};

export default EXTERNAL_URLS;
