import React from 'react';
import Grid from '@mui/material/Grid';
import { useSignOut } from 'react-auth-kit';
import { Container } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PlayerGameTable from '../../../compounds/PlayerGameTable/PlayerGameTable';
import EliasBackButton from '../../../atoms/EliasBackButton/EliasBackButton';
import ROUTER_URLS from '../../../utils/router/urls';
import ResultsLoading from '../../../compounds/ResultsLoading/ResultsLoading';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { useFilterContext, useFilterDispatchContext } from '../../../contexts/FilterContext';
import { fetchSearch } from '../../../../api/api';
import { fetchNbaPlayerGameStats } from '../../../../api/nba';
import { statsFilters } from './constants';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { appendContextFilters } from '../../../utils/nba/search';
import PlayerGameGroupBy from '../../../compounds/PlayerGameGroupBy/PlayerGameGroupBy';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories, gameModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NbaPlayerFiltersLayout from '../../../compounds/nba/NbaPlayerFiltersLayout';
import { saveSearchAndNavigate } from '../../../utils/common/search';
import { mapRows } from '../../../utils/nba/game-finder/map-results';
import { mapColumns } from '../../../utils/nba/game-finder/map-columns';

export const DEFAULT_PLAYER_GAME_ORDERING = {
  field: 'game_date',
  sort: 'desc',
};

function PlayerGameFinderResults() {
  const navigate = useNavigate();
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();
  const signOut = useSignOut();
  const location = useLocation();
  const { searchId } = useParams();

  const [columnHeaders, setColumnHeaders] = React.useState<any>();
  const [playerGameData, setPlayerGameData] = React.useState<any>();
  const [tableDataLoading, setTableDataLoading] = React.useState(false);
  const [filtersLoading, setFiltersLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>();

  const { loadFiltersFromState } = location.state ?? { loadFiltersFromState: false };

  // @todo: reminder that this isnt ideal
  // as filters continue to move into the context clean this up
  const fetchPlayerGameData = (sortBy: string, page: number, uuid?: string) => {
    const filters: string[] = [];
    const combinedFilters = appendContextFilters(filters, filterContext, statsFilters);

    setError(null);
    setTableDataLoading(true);
    fetchNbaPlayerGameStats(
      combinedFilters,
      signOut,
      page,
      sortBy,
      uuid,
    ).then((data: any) => {
      const rows = mapRows(data.results, filterContext.groupBy, page);
      const mappedData = {
        ...data,
        results: rows,
      }
      setPlayerGameData(mappedData);
      setTableDataLoading(false);
      setError(null);
    }).catch((responseError) => {
      setTableDataLoading(false);
      setError(responseError)
      filterDispatchContext({
        type: 'changeSearchStatus',
        searchEnabled: true,
      });
    });
  };

  const fetchFilterContext = (id: string) => {
    setFiltersLoading(true);
    fetchSearch(id).then((data: any) => {
      const filtersFromApi = data.context;
      const apiFiltersContext = filtersFromApi.filterContext;
      filterDispatchContext({
        type: 'loadFilters',
        apiFilters: apiFiltersContext,
      });
      const columns = mapColumns(apiFiltersContext.groupBy, apiFiltersContext.selectedStatsFilters);
      setColumnHeaders(columns || []);
      fetchPlayerGameData(apiFiltersContext.sortBy, apiFiltersContext.page, id);
      setFiltersLoading(false);
    })
  };

  React.useEffect(() => {
    if (searchId && searchId.length > 0 && (!loadFiltersFromState)) {
      fetchFilterContext(searchId);
    } else {
      const columns = mapColumns(filterContext.groupBy, filterContext.selectedStatsFilters);
      setColumnHeaders(columns || []);
      fetchPlayerGameData(filterContext.sortBy, filterContext.page, searchId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId]);

  const resetFilters = () => {
    filterDispatchContext({
      type: 'resetFilters',
    });
  };

  const fetchDataAndMapResults = (sortByParam: string, pageParam: number) => {
    const columns = mapColumns(filterContext.groupBy, filterContext.selectedStatsFilters);
    setColumnHeaders(columns);
    fetchPlayerGameData(sortByParam, pageParam);
  };

  const saveSearchSuccessFunction = (id: string) => {
    navigate(`${ROUTER_URLS.nba.playerGameFinderResults}/${id}`, {
      state: { loadFiltersFromState: true },
      replace: true,
    });
  };

  const handleDone = () => {
    filterDispatchContext({
      type: 'pageByChanged',
      page: 0,
    });
    filterDispatchContext({
      type: 'sortByChanged',
      sortBy: '-game_date',
    });
    filterDispatchContext({
      type: 'changeSearchStatus',
      searchEnabled: false,
    });
    // fetchDataAndMapResults('-game_date', 0);
    const filters: string[] = [];
    const combinedFilters = appendContextFilters(filters, filterContext, statsFilters);
    const queryString = combinedFilters.join('&')
    saveSearchAndNavigate(filterContext, queryString, saveSearchSuccessFunction);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo />;
  const groupByPartial = <PlayerGameGroupBy />
  const topModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
    />
  );

  return (
    <Dashboard loading={filtersLoading}>
      <Container sx={{ py: 2 }} maxWidth="xl">
        <Grid
          container
          direction="column"
          spacing={4}
          className="player-game-finder-container"
        >
          <Grid item>
            <EliasBackButton route={ROUTER_URLS.nba.playerGameFinderSearch} />
          </Grid>
          <Grid item>
            <NbaPlayerFiltersLayout
              resetFilters={resetFilters}
              handleDone={handleDone}
              statsFilterPartial={statsFilterPartial}
              pageSpecificSearchEnabled={pageSpecificSearchEnabled}
              groupByPartial={groupByPartial}
              apiUrls={API_URLS.nba}
              topModalPartial={topModalPartial}
              bottomModalPartial={bottomModalPartial}
            />
          </Grid>
          <Grid item sx={{ py: 3 }}>
            { tableDataLoading || error ?
              <ResultsLoading error={error} /> :
              <PlayerGameTable
                data={playerGameData?.results ?? []}
                dataCount={playerGameData?.count ?? 0}
                dataMetaInfo={playerGameData?.query?.back_to ?? []}
                columnData={columnHeaders || []}
                handleDone={fetchDataAndMapResults}
              />
            }
          </Grid>
        </Grid>
      </Container>
    </Dashboard>
  );
}

export default PlayerGameFinderResults;
