import React from 'react';
import { Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import EliasDataGrid from '../../atoms/EliasDataGrid/EliasDataGrid';
import './PlayerGameTable.scss';
import { GroupedPlayerInstance, PlayerGameResult, ResultDetail } from '../../../api/types';
import CopyButton from '../../atoms/CopyButton/CopyButton';
import { createCsvString, formatResultDetail } from '../../utils/common/results';
import { AppLeagueRoutesType } from '../../utils/router/urls';

interface PlayerGameTableProps {
  data: PlayerGameResult[] | GroupedPlayerInstance[];
  dataCount: number;
  dataMetaInfo: ResultDetail[];
  columnData: any;
  handleDone: (sortByParam: string, pageParam: number) => void;
  appUrls?: AppLeagueRoutesType;
}

function PlayerGameTable({
  data,
  dataCount,
  dataMetaInfo,
  columnData,
  handleDone,
  appUrls,
}: PlayerGameTableProps) {
  const total = dataCount;
  const resultExplanationText = formatResultDetail(dataMetaInfo);

  const copyCsvDataFromResults = () => {
    const columnHeaders = columnData.map((column: any) => column.headerName);
    const fields = columnData.map((column: any) => column.field);
    const csv = createCsvString(columnHeaders, fields, data);
    navigator.clipboard.writeText(csv);
  };

  return (
    <Paper elevation={3}>
      <Grid2 container spacing={2} minHeight={75}>
        <Grid2 xs={2} display="flex" justifyContent="center" alignItems="center">
          <h3 className="grid-total player-game-results-total">{total} Results</h3>
        </Grid2>
        <Grid2 xs={8} display="flex" justifyContent="flex-start" alignItems="center">
          <p className="grid-explanation">{resultExplanationText}</p>
        </Grid2>
        <Grid2 xs={2} display="flex" justifyContent="center" alignItems="center">
          <CopyButton onClick={copyCsvDataFromResults} />
        </Grid2>
      </Grid2>
      <EliasDataGrid
        columns={columnData}
        rows={data}
        rowCount={total}
        handleDone={handleDone}
        appUrls={appUrls}
      />
    </Paper>
  );
}

export default PlayerGameTable;
