import React from 'react';
import Button from '@mui/material/Button';
import ButtonStyles from './StyleOverrides';
import CopyIcon from '../../../assets/Icons/CopyIcon';

interface CopyButtonProps {
  onClick: () => void;
}

function CopyButton({ onClick }: CopyButtonProps) {
  return (
    <Button
      variant="outlined"
      startIcon={<CopyIcon className="copy-icon" />}
      onClick={onClick}
      className="copy-button"
      sx={ButtonStyles}
    >
      Copy
    </Button>
  );
}

export default CopyButton;
