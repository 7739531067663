import { ColumnMappingType } from "../../../types/results";
import { SelectedStatFilterType } from "../../../types/statsFilter";
import { showHideColumns } from "../../common/results";

const DEFAULT_COL_WIDTH = 80;
const TINY_COL_WIDTH = 10;
const MEDIUM_COL_WIDTH = 145;
const WIDE_COL_WIDTH = 185; // fits longest player display name at 24 characters

export const ungroupedColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: 40,
    sortable: false,
    display: 'alwaysFirst',
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'Golfer',
    field: 'golfer__display_name',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Nationality',
    field: 'golfer__nationality',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Name',
    field: 'tournament__name',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Year',
    field: 'tournament__year',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Course',
    field: 'tournament__golf_course',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Tourn. Type',
    field: 'tournament__tournament_class',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: ' ',
    field: 'loc',
    width: TINY_COL_WIDTH,
    sortable: false,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Finish',
    field: 'finish',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Score to Par',
    field: 'score_to_par',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'RD1',
    field: 'round_1_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'RD2',
    field: 'round_2_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'RD3',
    field: 'round_3_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'RD4',
    field: 'round_4_score',
    width: TINY_COL_WIDTH,
    display: 'alwaysFirst',
  },
  {
    headerName: 'Total Score',
    field: 'total_score',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },

  // don't forget "whenSelected"
];

export const playerColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerFranchiseColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Franchise',
    field: 'franchise_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerOpponentColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Opponent',
    field: 'opponent_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerFranchiseSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Franchise',
    field: 'franchise_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const playerOpponentSeasonColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  {
    headerName: 'Player',
    field: 'display_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Opponent',
    field: 'opponent_name',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
];

export const mapColumns = (groupByColumn: string, localFilters: SelectedStatFilterType[]) => {
  switch (groupByColumn) {
    case 'player':
      return playerColumnMapping;
    case 'player-season':
      return playerSeasonColumnMapping;
    case 'player-franchise':
      return playerFranchiseColumnMapping;
    case 'player-opponent':
      return playerOpponentColumnMapping;
    case 'player-franchise-season':
      return playerFranchiseSeasonColumnMapping;
    case 'player-opponent-season':
      return playerOpponentSeasonColumnMapping;
    default:  
      return showHideColumns(ungroupedColumnMapping, localFilters);
  };
};