import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { TableCell, TableRow } from '@mui/material';
import FactCard from '../../atoms/FactCard/FactCard';
import StyledTable from '../../atoms/StyledTable/StyledTable';
import FinderSelect from '../../atoms/FinderSelect/FinderSelect';
import GolfScorecard from '../GolfScorecard/GolfScorecard';
import { PlayerProfileStatsType, PlayerProfileType } from '../../types/player';

interface IProps {
  courseDetails: PlayerProfileType;
  courseStatsData: PlayerProfileStatsType;
};

const golfHoleHeaders = [
  'Hole #',
  // 'Nickname',
  'Par',
  'Yardage',
  'Scoring Average',
  'Avg. to Par',
  'Diff. Rank',
  '# Birdies',
  '# Pars',
  '# Bogeys',
  '# Eagles',
  '# Double or Worse',
  '# Holes-in-One'
];

const golfHoleMap = {
  'Hole #' : 'holeNumber',
  // 'Nickname': 'nickname',
  'Par' : 'par',
  'Yardage' : 'yardage',
  'Scoring Average' : 'scoringAverage',
  'Avg. to Par' : 'averageToPar',
  'Diff. Rank' : 'difficultyRank',
  '# Birdies' : 'numBirdies',
  '# Pars' : 'numPars',
  '# Eagles' : 'numEagles',
  '# Bogeys' : 'numBogeys',
  '# Double or Worse' : 'numDoubleOrWorse',
  '# Holes-in-One' : 'numHolesInOne',

}

const mapCourseStatsData = (data: PlayerProfileStatsType) => {
  const holeData = data.yearly_stats['2023'];

  return Object.keys(holeData).map((key: string) => {
    const holeRow = holeData[key];
    return {
      id: key,
      holeNumber: holeRow.holeNumber,
      par: holeRow.par,
      yardage: holeRow.yardage,
      scoringAverage: holeRow.scoringAverage,
      averageToPar: holeRow.averageToPar,
      difficultyRank: holeRow.difficultyRank,
      numBirdies: holeRow.numBirdies,
      numPars: holeRow.numPars,
      numBogeys: holeRow.numBogeys,
      numEagles: holeRow.numEagles,
      numDoubleOrWorse: holeRow.numDoubleOrWorse,
      numHolesInOne: holeRow.numHolesInOne,
      nickname: holeRow.nickname,
    };
  });
};

const mapCourseRecordHolders = (courseRecordHolders: PlayerProfileStatsType) => {
  console.log(courseRecordHolders);
  return Object.keys(courseRecordHolders).map((key: string) => {

    const courseRecordHolder = courseRecordHolders[key];
    return `${courseRecordHolder.golfer} - ${courseRecordHolder.tournament}, ${courseRecordHolder.year}, ${courseRecordHolder.roundNumber}`;

  });
};

function GolfCourseLayout({ courseDetails, courseStatsData }: IProps) {

  const mappedCourseStatsData = mapCourseStatsData(courseStatsData);
  const courseRecordHoldersMap = mapCourseRecordHolders(courseDetails.courseRecord.heldBy);

  const yearOptions = [
    { id: '2023-PGA-Championship', label: '2023 PGA Championship' },
    { id: '2013-PGA-Championship', label: '2013 PGA Championship' },
    { id: '2003-PGA-Championship', label: '2003 PGA Championship' },
    { id: '1989-US-Open', label: '1989 US Open' },
    { id: '1980-PGA-Championship', label: '1980 PGA Championship' },
    { id: '1968-US-Open', label: '1968 US Open' },
    { id: '1956-US-Open', label: '1956 US Open' },
    { id: '1942-Rochester-Open', label: '1942 Rochester Open' },
    { id: '1941-Rochester-Times-Union-Open', label: '1941 Rochester Times Union Open' },
  ]

  return (
    <Grid container direction="column" sx={{ margin: 0 }}>
      <Grid container direction="row" marginTop={5}>
        <Grid item>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} justifyContent='center'>
            <Typography component="h1" variant="h3" fontWeight="600">{courseDetails.name}</Typography>
          </Box>
        </Grid>
      </Grid> 

      <Grid container direction="row" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 3 }}>
        <Grid item xs={3}>
          <FactCard label='Par' values={[courseDetails.par]} />
          <FactCard label='Length' values={[courseDetails.length]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Fairway Grass' values={[courseDetails.fairwayGrass]} />
          <FactCard label='Greens Grass' values={[courseDetails.greensGrass]} />
        </Grid>

        <Grid item xs={3}>
            <FactCard label='Course Record' values={[courseDetails.courseRecord.score]} />
            <FactCard label='Held By' values={[courseRecordHoldersMap]} useLineBreakForValues={1} />
         </Grid>
      </Grid>

      <Grid container mt={5} marginRight={5}>
        <Grid item xs={12}>
          <GolfScorecard
            data={courseDetails.holes}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={14}>
                  <h5 style={{ display: 'inline-block', fontSize: '1.4em', marginRight: 3 }}>Current Scorecard<br/><span style={{ display: 'inline-block', fontSize: '0.8em', fontStyle: 'italic' }}>as of August 31, 2023</span></h5>
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>

      <Grid container mt={5} marginRight={5}>
        <Grid item xs={12}>
          <StyledTable
            uniqueRowKey='id'
            headers={golfHoleHeaders}
            data={mappedCourseStatsData}
            dataMap={golfHoleMap}
            linkableItems={['year', 'golfCourse', 'winner', 'golfer', 'tournamentName']}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={2}>
                  <h5 style={{ display: 'inline-block', fontSize: '1.4em', marginRight: 3 }}>Course Stats</h5>      
                </TableCell>     
                <TableCell colSpan={4}>
                  <FinderSelect
                    label='Tournament'
                    value='2023-PGA-Championship'
                    options={yearOptions}
                  />
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GolfCourseLayout;